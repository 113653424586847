.dataTable{
    height: 100%;
    padding: 20px;
    position: relative;
}

.datatableTitle{
    width: 100%;
    padding: 1rem;
    margin-bottom: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
}

.table-link{
    text-decoration: none;
    color: var(--green);
    background: rgba(32, 201, 10, 0.208);
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid var(--green);
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

@media screen and (min-width: 1500px) {
    .table-link {
      font-size: 1.2rem;
      padding: 10px;
    }
}