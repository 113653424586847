/* ADVERTISEMENT CSS */
/* *************************************8 */

.hero-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.hero-ad {
  position: fixed;
  z-index: 5;
  width: 80%;
  height: 80vh;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 3rem 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.ad-img {
  width: 100%;
  height: 100%;
  padding: 2rem 6rem;
  border-radius: 10px;
  object-fit: cover;
  z-index: 1;
}
.ad-close-icon {
  position: absolute;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  top: 1rem;
  cursor: pointer;
  color: aliceblue;
  z-index: 2;
}
.hero-modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.hero-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

/* HERO PAGE CSS */
.hero-modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.hero-video1 {
  position: absolute;
  z-index: 115;
  width: 80%;
  height: 80vh;
  background: rgba(0, 0, 0, 0.4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ad-close-icon {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  color: aliceblue;
}

.hero {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 3rem;
  z-index: 1;
}

.left-hero {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 3;
}

.hero-h3 {
  background-color: var(--littleDark);
  border-radius: 4rem;
  padding: 1rem 0.8rem;
  width: 35vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hero-slider-text {
  z-index: 2;
  padding-left: 1rem;
  letter-spacing: 1px;
}

.hero-slider {
  position: absolute;
  background-color: var(--secondary);
  width: 5.4rem;
  height: 80%;
  border-radius: 3rem;
  left: 8px;
  z-index: 1;
  animation: slideAnimation 4s infinite ease-in-out;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(130px);
  }
  100% {
    transform: translateX(0);
  }
}

.hero-main-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 2.2rem;
  font-weight: 500;
  text-overflow: inherit;
}

.hero-span {
  font-size: 1.2rem;
  color: var(--light);
}

.hero-button {
  display: flex;
  gap: 1rem;
}

.hero-button > :nth-child(2) {
  color: var(--color);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.right-hero {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.right-hero > img {
  width: 33.8rem;
  margin-top: 10px;
  position: absolute;
  z-index: 1;
  padding-right: 1.5rem;
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.9));
  animation: imageAnimation 3s;
}

@keyframes imageAnimation {
  0% {
    transform: translateX(-45%);
  }
  100% {
    transform: translateX(0);
  }
}

.polygon {
  height: 13rem;
  width: 31rem;
  margin-top: 10rem;
  background: var(--secondary);
  clip-path: polygon(25% 0%, 100% 0%, 100% 95%, 0% 100%);
}

@media screen and (max-width: 914px) {
  .hero-h3 {
    width: 40vw;
  }
  .hero-main-text {
    font-size: 1.5rem;
  }
  .hero-span {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-ad {
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 3rem 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    height: 70vh;
  }
  .ad-img {
    padding: 0px 1rem;
    object-fit: fill;
  }
  .ad-close-icon {
    right: 3px;
    top: 3px;
  }

  .hero {
    flex-direction: column-reverse;
  }
  .hero-h3 {
    margin-top: 2rem;
    font-size: 0.8rem;
    transform: scale(0.8);
    width: 100%;
  }
  .hero-main-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-button {
    justify-content: center;
  }
  .right-hero {
    position: relative;
  }
  .polygon {
    width: 25rem;
  }
  .hero-video1 {
    width: 93%;
    height: 67vh;
  }
}

@media screen and (max-width: 420px) {
  .ad-img {
    padding: 0px 12px;
  }
  .left-hero {
    padding: 0rem;
  }
  .hero-main-text {
    font-size: 1.5rem;
  }
  .polygon {
    height: 10rem;
    width: 20rem;
  }
  .right-hero img {
    width: 29.5rem;
  }
  .svg {
    width: 2rem;
  }
  .watch > span {
    font-size: 15px;
  }
  .hero-span {
    font-size: 0.8rem;
  }
}
