.singleProgram-container{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
}

.singleProgram-container > .program-header{
    text-align: start;
    margin: 2rem 0;
    color: var(--secondary);
    font-size: 2rem;
    font-weight: 600;
    text-transform: capitalize;
}

.single-program{
    display: flex;
    gap: 2rem;
    color: var(--color);
    font-weight: 500;
}

.single-program-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.single-program-left > img{
    width: 100%;
    height: 350px;
    border-radius: 10px;
}

.single-program-right{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: capitalize;
    overflow: hidden;
}

.single-program-name,
.single-program-date, 
.single-program-desc{
    letter-spacing: 0.5px;
    font-size: 0.9rem;
    font-weight: 400;
}

.single-program-right > .send-btn{
    width: 10rem;
}

@media screen and (max-width: 760px) {
    .single-program{
        flex-direction: column;
    }    
    .single-program-left, .single-program-right{
        width: 100%;
    }
    .singleProgram-container > .program-header{
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .singleProgram-container{
        padding: 0 1rem;
    }
    .single-program-left > img{
        height: 300px;
    }
}

/* PROSPeCT CODE */
/* ********************** */

.prospect{
    width: 100vw;
    height: 100vh;
    background-color: var(--primary);
    position: relative;
}
.prospect-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 50vw;
    border-radius: 10px;
    background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.1));
    backdrop-filter: blur(20px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255,255,255,0.18);
}
.prospect-title{
    color: var(--secondary);
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
}
.prospect-icon{
    position: absolute;
    right: 1rem;
    top: 1rem;
    transform: scale(0.86);
    border-radius: 50%;
    cursor: pointer;
}
.prospect-container form{
    padding: 0 2rem;
    height: 400px;
    margin-top: 1rem;
    overflow-y: scroll; 
    display: flex;
    flex-direction: column;
}

.prospect-container form::-webkit-scrollbar {
    display: flex;
    width: 4px; /* Width of the scrollbar */
  }
  
  .prospect-container form::-webkit-scrollbar-thumb {
    background-color: #a18f8f; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners of the thumb */
  }
  
  .prospect-container form::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb on hover */
  }

.prospect-text{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}
.prospect-p{
    color: var(--color);
    font-size: 0.7rem;
}
.asterick {
    color: var(--secondary);
    margin-left: 5px;
}
.prospect-container form .prospect-text label{
    color: var(--white);
    font-size: 1rem;
    font-weight: 500;
}
.prospect-file-icon{
    transform: scale(0.85);
    color: var(--white);
    cursor: pointer;
}

.prospect-doc{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.prospect-doc-name{
    color: var(--secondary);
}

.deleted-icon{
    color: var(--red);
    cursor: pointer;
}

.prospect-container form .prospect-text input, 
.prospect-container form .prospect-text select{
    padding: 0.4rem 1rem;
    border-radius: 7px;
    outline: none;
    border: none;
    margin-top: 5px;
    cursor: pointer;
}

.prospect-form-btn{
    border-radius: 7px;
}

@media screen and (max-width: 786px) {
    .prospect-container{
        width: 80vw;
    }
}


@media screen and (max-width: 500px) {
    .prospect-container{
        width: 95vw;
        height: 90vh;
        overflow-y: scroll;
    }
    .prospect-container form {
        height: 600px;
        padding: 0 1rem;
        overflow-y: scroll;
    }
}
