.main-calendar{
    padding: 1rem 1.2rem;
    overflow-x: scroll;
}

@media screen and (max-width: 2000px) {
    .fc .fc-toolbar-title {
        font-size: 1.2rem;
        margin: 0;
    }
    .fc .fc-button-primary:disabled, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child), .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child)  {
        background-color: var(--dash-primary);
        opacity: 0.86;
        color: var(--color);
        font-size: 16px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .fc .fc-button-primary:disabled:hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child):hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child):hover{
        background-color: var(--dash-dark);
        opacity: 1;
    }
}

@media screen and (max-width: 500px) {
    .fc .fc-toolbar-title {
        font-size: 1rem;
        margin: 0;
    }
    .fc .fc-button-primary:disabled, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child), .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child)  {
        background-color: var(--dash-primary);
        opacity: 0.56;
        color: var(--color);
        font-size: 14px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .fc .fc-button-primary:disabled:hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child):hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child):hover{
        background-color: var(--dash-dark);
        opacity: 1;
    }
    .main-calendar1{
        width: 700px;
        overflow-x: scroll;
    }
}

@media screen and (max-width: 400px) {
    .fc .fc-toolbar-title {
        font-size: 14px;
        margin: 0;
    }
    .fc .fc-button-primary:disabled, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child), .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child)  {
        background-color: var(--dash-primary);
        opacity: 0.56;
        color: var(--color);
        font-size: 12px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .fc .fc-button-primary:disabled:hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child):hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child):hover{
        background-color: var(--dash-dark);
        opacity: 1;
    }
}

@media screen and (max-width: 350px) {
    .fc .fc-toolbar-title {
        font-size: 0.7rem;
        margin: 0;
    }
    .fc .fc-button-primary:disabled, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child), .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child)  {
        background-color: var(--dash-primary);
        opacity: 0.56;
        color: var(--color);
        font-size: 9px;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .fc .fc-button-primary:disabled:hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child):hover, .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child):hover{
        background-color: var(--dash-dark);
        opacity: 1;
    }
}

@media screen and (max-width: 280px) {
    .main-calendar{
        padding: 1rem 3px;
    }
}