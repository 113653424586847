.contact {
  display: flex;
  justify-content: space-evenly;
  padding: 0 2rem;
}

.contact-h1 {
  font-weight: 400;
}

.contact-container {
  border: 1px solid var(--secondary);
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 1px 2px 5px var(--secondary);
  width: 50%;
  margin-top: 1rem;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.contact-h3 {
  color: var(--secondary);
  font-size: 1.5rem;
  font-size: 400;
}

.contact-h4 {
  color: var(--light);
  font-size: 1.2rem;
  font-weight: 400;
}

.contact-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2rem;
}

.contact-input {
  border-radius: 0.5rem;
  padding: 0.5rem 1.2rem;
  font-weight: 500;
  outline: none;
  border: none;
}

.people {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 2rem;
}

.people > div > p {
  color: var(--color);
}

.people > div > p > span {
  color: var(--secondary);
  font-weight: 600;
}

@media screen and (max-width: 850px) {
  .contact {
    flex-direction: column;
  }
  .contact-container {
    width: 100%;
  }
  .people {
    gap: 3rem;
  }
}

@media screen and (max-width: 400px) {
  .contact {
    padding: 0 1rem;
  }
  .contact-header {
    font-size: 1.2rem;
  }
  .contact-container {
    padding: 0.8rem;
  }
  .contact-h3,
  .contact-h4 {
    font-size: 0.9rem;
  }
  .contact-input {
    padding: 0.5rem 1rem;
  }
  .people > div > p {
    font-size: 12px;
  }
  .people > div > p > span {
    font-size: 16px;
  }
}
