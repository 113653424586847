.navbar{
    height: 2.5rem;
    border-bottom: 2px solid var(--light);
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--dash-dark);
    position: sticky;
    top: 0;
    z-index: 2;
}

.navbar .wrapper{
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: flex-end;
    width: 100%;
}

.navbar .items{
    display: flex;
    margin-right: 3vw;
}

.items .item{
    display: flex;
    align-items: center;
    margin-left: 1rem;
    cursor: pointer;
    position: relative;
}

.items .item .icon{
    font-size: 1.2rem;
    fill: var(--dash-dark);
}

.ripple::after, 
.ripple::before{
  content: "";
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--color);
  z-index: -1;
  opacity: 0.7;
}

.ripple::before{
  animation: notification 2s ease-out;
}

.ripple::after{
  animation: notification 2s ease-out;
}

@keyframes notification {
    100%{
      transform: scale(2);
      opacity: 0;
    }
}

.items .avatar{
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
}

.items .item .counter{
    width: 1rem;
    height: 1rem;
    background-color: var(--red);
    border-radius: 50%;
    color: var(--color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: -3px;
    right: -5px;
    z-index: 1;
}

@media screen and (max-width:500px) {
    .items .item .language-options {
        padding: 0.2rem 0.5rem;
        font-size: 14px;
    }
    .items .item {
        margin-left: 0.74rem;
    }
    .items .avatar {
        width: 1.5rem;
        height: 1.5rem;
    }
    .items .item .icon{
        font-size: 1rem;
    }
    .items .item .counter{
        top: -4px;
    }
    
}

  