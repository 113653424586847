.plans{
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--black);
    position: relative;
    margin-top: 3rem;
}
.plans-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.plans-h5{
    color: var(--secondary);
    font-size: 1.2rem;
    font-weight: 600;
}
.plans-h2{
    font-size: 2.2rem;
    font-weight: 500;
}
.plans-container{
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 3rem;
}
.plans-table {
    display: flex;
    flex-direction: column;
    background: var(--lightgray);
    gap: 1.5rem;
    padding: .75rem;
    border-radius: .5rem;
    width: 26%;
}
.plans-table:hover{
    cursor: pointer;
    box-shadow: rgba(238, 101, 9, 0.25) 0px 14px 28px, rgba(238, 101, 9, 0.4)  0px 10px 10px;
    transition: 0.6s ease-in-out;
}
.plans-table:nth-child(2) {
    background: var(--littleDark);
    transform: scale(1.1);
}
.plans-table > svg {
    fill: var(--secondary);
    width: 2rem;
    height: 2rem;
}
.plans-btn{
    width: 100%;
    border-radius: .5rem;
}
.plans-table>:nth-child(2) {
    font-size: 1rem;
    font-weight: 600;
}
.plans-table>:nth-child(3) {
    font-size: 3rem;
    font-weight: 300;
}
.plans-table>:nth-child(5) {
    font-size: 0.8rem;
}
.plans-features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.plans-feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.plans-feature > img {
    width: 1rem;
}
.plans-table>:nth-child(2)>svg {
    fill: var(--color);
}

@media screen and (max-width: 800px) {
    .plans-container{
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    .plans-table{
        width: 100%;
    }
    .plans-table:nth-child(2) {
        transform: scale(1);
    }
    .plans-h2{
        font-size: 1.5rem;
        margin-top: 1rem;
    }
    .plans-h5{
        font-size: 1.3rem;
    }
}

 @media screen and (max-width: 400px){
    .plans{
        gap: 1rem;
        margin-top: 2rem;
    }
    .plans-h5 {
        font-size: 1.3rem;
    }
    .plans-h2 {
        font-size: 1rem;
    }
    .plans-table {
        padding: 1rem;
    }
    .plans-table>svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media screen and (max-width: 300px) {
    .plans-table{
        font-size: 12px;
    }
    .plans-table:nth-child(2) {
        font-size: 14px;
    }
    .plans-btn {
        font-size: 12px;
    }
}