.article-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 2rem;
}

.article-container .website-header {
  font-size: 1.5rem;
}

.article-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 5rem;
}

.article-box .article-image {
  width: 50%;
  margin-right: 2.4rem;
}

.article-box .article-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.article-heading {
  width: 50%;
  margin-right: 2.5rem;
}

.article-heading .article-h4 {
  font-weight: bold;
  color: var(--secondary);
  font-size: 1.2rem;
  margin: 1rem 0;
  text-transform: capitalize;
}

.article-heading .article-p {
  font-size: 1rem;
  color: var(--light);
  margin-bottom: 1rem;
  text-align: justify;
}

.article-heading .Link {
  text-decoration: none;
  color: var(--color);
}

.article-heading .Link:hover {
  color: var(--light);
}

/* single Article */
/* ********************************* */

.singleArticle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
  padding: 1rem 2rem;
}

.singleArticle-title {
  color: var(--secondary);
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin: 1.5rem 0;
  text-transform: capitalize;
}

.singleArticle-photo {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.singlephoto-left {
  width: 66%;
}

.singlephoto-left img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.singlephoto-right img {
  height: 10rem;
  width: 10rem;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.article-date {
  color: var(--light);
  margin-top: 1rem;
  text-transform: capitalize;
}

.singleArticle-article {
  color: var(--white);
  font-size: 1rem;
  line-height: 1.6rem;
  margin-top: 2rem;
  text-align: justify;
  column-count: 3;
  column-gap: 2rem;
}

@media screen and (max-width: 900px) {
  .singleArticle-photo {
    padding: 0;
  }
  .singleArticle-article {
    padding: 0;
  }
  .singleArticle-container {
    padding: 0rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .article-title {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .article-box {
    flex-direction: column;
    margin-left: 1rem;
  }
  .article-box .article-image {
    width: 100%;
    height: 100%;
  }
  .article-heading {
    width: 100%;
  }
  .singleArticle-photo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .singlephoto-left {
    width: 100%;
  }
  .singlephoto-right {
    display: flex;
    flex-direction: column;
  }
  .singlephoto-right img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }
  .singleArticle-article {
    column-count: 2;
  }
}

@media screen and (max-width: 400px) {
  .singleArticle-container {
    padding: 0rem 1rem;
  }
  .article-container {
    padding: 1rem;
  }
  .article-container .article-title {
    font-size: 1.2rem;
  }
  .singlephoto-left img {
    height: 15rem;
  }
  .article-heading .article-p {
    font-size: 0.8rem;
  }
  .article-date {
    font-size: 0.8rem;
  }
  .singleArticle-article {
    font-size: 0.9rem;
    column-count: 1;
  }
}
