/* .loader { 
  width: 100%;
  height: 100%;
  position: absolute;
  left: 46%;
  top: 50%;
}
.loader-s { 
  position: relative;
  width: auto;
  height: auto;
}
.circle-loader { 
  height: 10px;
  width: 10px;
  background-color: #f44336;
  border-radius: 50%;
  position: absolute;
  animation: 0.8s opaque ease-in-out infinite both;
} */

/* .circle-loader > div:nth-child(1) {
  top: -25px;
  left: 0;
}
.circle-loader > div:nth-child(2) {
  top: -17px;
  left: 17px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.circle-loader > div:nth-child(3) {
  top: 0;
  left: 25px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.circle-loader > div:nth-child(4) {
  top: 17px;
  left: 17px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.circle-loader > div:nth-child(5) {
  top: 25px;
  left: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.circle-loader > div:nth-child(6) {
  top: 17px;
  left: -17px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.circle-loader > div:nth-child(7) {
  top: 0;
  left: -25px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.circle-loader > div:nth-child(8) {
  top: -17px;
  left: -17px;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

@-webkit-keyframes opaque {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes opaque {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
} */

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container {
  width: 5rem;
  height: 5rem;
  position: relative;
}

.loader-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  left: 30%;
  z-index: 222;
  border: 5px solid transparent;
  border-top-color: var(--dash-primary);
  border-left-color: var(--dash-primary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
