.chat-section {
  width: 100vw;
  height: 100vh;
  background-color: var(--lightpink);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--primary);
  box-shadow: var(--box-shadow);
  padding: 0.7rem;
  display: flex;
  overflow: hidden;
}
.chat-content {
  display: flex;
  flex: 95%;
}

/* MESSAGE ROUTES PAGE CSS */
/* ********************************************** */
.chat-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.7rem 0.7rem 0.7rem 0;
}
.chat-sidebar-logo {
  display: block;
  text-align: center;
  padding: 12px 8px;
  font-size: 25px;
  text-decoration: none;
  color: var(--white);
}
.chat-sidebar-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  list-style: none;
  flex-grow: 1;
}
.chat-sidebar-menu > * > a {
  display: block;
  text-align: center;
  padding: 8px 12px;
  font-size: 1rem;
  text-decoration: none;
  color: var(--light);
  border-radius: 10px;
  position: relative;
  transition: color 0.15s ease-in-out;
}
.chat-sidebar-menu > * > a:hover {
  color: var(--white);
}
.chat-sidebar-menu > .active > a {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.1);
}
.chat-sidebar-menu > * > a::before {
  content: attr(data-title);
  position: absolute;
  top: 50%;
  left: calc(100% - 12px);
  border-radius: 4px;
  transform: translateY(-50%);
  font-size: 13px;
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in-out;
}
.chat-sidebar-menu > * > a:hover::before {
  left: calc(100% - 8px);
  opacity: 1;
  visibility: visible;
}
.chat-sidebar-profile {
  position: relative;
  margin-top: auto;
}
.chat-sidebar-profile-toggle {
  background-color: transparent;
  border: none;
  outline: transparent;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}
.chat-sidebar-profile-toggle > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-sidebar-profile-dropdown {
  position: absolute;
  bottom: 3rem;
  right: -1px;
  background-color: var(--color);
  width: max-content;
  list-style-type: none;
  border-radius: 4px;
  padding: 6px 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transform-origin: left bottom;
  transition: all 0.15s ease-in-out;
}
.chat-sidebar-profile.active .chat-sidebar-profile-dropdown {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.chat-sidebar-profile-dropdown a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-decoration: none;
  color: var(--black);
  font-size: 14px;
}
.chat-sidebar-profile-dropdown a:hover {
  color: var(--black1);
  background-color: var(--light);
}
.chat-sidebar-profile-dropdown a:active {
  color: var(--dash-dark);
}
.chat-sidebar-profile-dropdown a .message-cons {
  font-size: 17px;
}

/* CHAT  CONTENT  SIDEBAR  */
/* *********************************** */
.content-sidebar {
  flex: 3;
  border-radius: 10px 0 0 10px;
  background-color: var(--message-bg2);
  padding: 1rem 0.5rem;
  overflow: hidden;
}
.content-sidebar.collapsed {
  width: 100%;
}
.content-sidebar-form {
  position: relative;
  margin-top: 1rem;
}
.content-sidebar-input {
  padding: 14px 16px;
  background-color: var(--dash-fourth);
  color: var(--black);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 10px;
  padding-right: 3rem;
  font-size: 14px;
}
.content-sidebar-input::placeholder {
  color: var(--primary);
}
.content-sidebar-submit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  color: var(--dash-primary);
  background-color: transparent;
  outline: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
}
.content-sidebar-submit:hover {
  color: var(--dash-dark);
}
.content-messages {
  overflow-y: auto;
  height: 100%;
  margin-top: 1rem;
}
.content-messages-list {
  list-style: none;
  padding: 8px 0;
}
.content-messages-list > * > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  padding: 6px 0;
  margin-bottom: 0.3rem;
  border-radius: 10px;
}
.content-messages-list > * > a:hover {
  background-color: var(--message-bg);
}
.content-messages-list > .active > a {
  background-color: var(--message-bg);
}
.content-message-image {
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 12px;
}
.content-message-info {
  display: grid;
  margin-right: 12px;
  width: 100%;
}
.content-message-name {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}
.content-message-text {
  font-size: 0.7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--lightgray);
}
.content-message-more {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.content-message-unread {
  font-size: 10px;
  color: var(--white);
  background-color: rgb(255, 60, 0);
  padding: 2px 4px;
  border-radius: 50%;
}
.content-message-time {
  font-size: 13px;
  font-weight: 500;
  color: var(--lightgray);
}

/* no conversation selected */
.conversation {
  background-color: var(--color);
  border-radius: 0 10px 10px 0;
  height: 100%;
  display: none;
  width: 100%;
  position: relative;
  flex: 8;
}
.conversation-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.conversation.active {
  display: flex;
  flex-direction: column;
}
.conversation-top {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.conversation-back {
  background-color: transparent;
  border: none;
  outline: none;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: var(--dash-primary);
  margin-right: 12px;
  display: none;
}
.conversation-back:hover {
  color: var(--dash-dark);
}
.conversation-back:focus {
  color: var(--dash-dark);
}
.conversation-user {
  display: flex;
  align-items: center;
}
.conversation-user-image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
}
.conversation-user-position {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 1rem;
}
.conversation-user-name {
  font-size: 1.1rem;
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  font-weight: 600;
}
.conversation-user-status {
  font-size: 0.9rem;
  color: var(--lightgray);
}
.conversation-user-status::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--black);
  border-radius: 50%;
}
.conversation-user-status.online::before {
  background-color: var(--black1);
}
.conversation-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.conversation-buttons > * {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  color: var(--lightgray);
  margin-left: 4px;
}
.conversation-buttons > :hover {
  color: var(--black);
}
.conversation-buttons > :active {
  color: var(--black);
}
.conversation-main {
  overflow-y: auto;
  height: 100%;
  padding: 0 4px;
}
.conversation-wrapper {
  list-style: none;
}
.conversation-divider {
  text-align: center;
  font-size: 13px;
  margin-bottom: 16px;
  position: relative;
}
.conversation-divider span {
  display: inline-block;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  background-color: var(--dash-fourth);
  position: relative;
  z-index: 1;
}
.conversation-item {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 16px;
}
.conversation-item.me {
  flex-direction: row;
}
.conversation-item-side {
  margin-left: 8px;
}
.conversation-item.me .conversation-item-side {
  margin-right: 8px;
}
.conversation-item-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}
.conversation-item-content {
  width: 100%;
}
.conversation-item-wrapper:not(:last-child) {
  margin-bottom: 8px;
}
.conversation-item-box {
  max-width: 70%;
  position: relative;
  margin-left: auto;
}
.conversation-item.me .conversation-item-box {
  margin-left: unset;
}
.conversation-item-text {
  padding: 12px 8px 8px;
  background-color: var(--message-bg3);
  color: var(--color);
  box-shadow: var(--box-shadow);
  font-size: 14px;
  border-radius: 6px;
  line-height: 1.5;
  margin-left: 32px;
}
.conversation-item.me .conversation-item-text {
  margin-left: unset;
  margin-right: 32px;
  background-color: var(--message-bg1);
  box-shadow: var(--box-shadow);
  color: var(--black);
}
.logout-message-icons {
  color: var(--color);
}
.conversation-item-sender {
  font-size: 14px;
}
.conversation-item-time {
  font-size: 10px;
  display: block;
  text-align: right;
  margin-top: 4px;
  line-height: 1;
}
.conversation-item.me .conversation-item-time {
  color: black;
}
.conversation-item-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in-out;
}
.conversation-item.me .conversation-item-dropdown {
  left: unset;
  right: 0;
}
.conversation-item-wrapper:hover .conversation-item-dropdown {
  opacity: 1;
  visibility: visible;
}
.conversation-item-dropdown-toggle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  color: var(--dash-secondary);
  outline: transparent;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
.conversation-item-dropdown-toggle:hover {
  color: var(--dash-dark);
}
.conversation-item-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--white);
  color: var(--black);
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 0;
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transform-origin: left top;
  transition: all 0.15s ease-in-out;
}
.conversation-item.me .conversation-item-dropdown-list {
  left: unset;
  right: 0;
}
.conversation-item-dropdown.active .conversation-item-dropdown-list {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.conversation-item-dropdown-list li > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-decoration: none;
  color: var(--dash-primary);
  font-size: 13px;
  padding: 6px 12px;
}
.conversation-item-dropdown-list li > div:hover {
  background-color: var(--color);
  color: var(--dash-dark);
}

.conversation-form {
  display: flex;
  align-items: flex-end;
  background-color: inherit;
}
.conversation-form-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  background-color: var(--dash-fourth);
  margin: 5px 2rem;
  border-radius: 10px;
  padding: 5px 16px;
  width: 100%;
  align-items: center;
}
.emoji-picker {
  position: absolute;
  left: 2rem;
  bottom: 3rem;
  width: 80vw;
  border-radius: 10px;
  z-index: 100;
}
.conversation-form-input {
  border-radius: 4px;
  padding: 10px 32px 10px 16px;
  background-color: transparent;
  border: none;
  outline: none;
  font: inherit;
  font-size: 14px;
  resize: none;
  width: 100%;
  display: block;
  line-height: 1.5;
  max-height: calc(20px + ((14px * 2) * 6));
}
.conversation-form-input:focus {
  border-color: var(--dash-dark);
}
.conversation-form-record {
  font-size: 20px;
  color: var(--dash-primary);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.conversation-form-record:hover {
  color: var(--dash-dark);
}
.conversation-form-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  outline: transparent;
  font-size: 20px;
  color: var(--dash-secondary);
  cursor: pointer;
  flex-shrink: 0;
}
.conversation-form-button:hover {
  color: var(--dash-primary);
}
.conversation-form-button:active {
  color: var(--dash-dark);
}

/* new chat group */
.create-group-close-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  transform: scale(0.8);
  color: var(--color);
  background-color: var(--red);
  padding: 0.2rem;
  border-radius: 50%;
}
.new-chat-group.newLeft {
  left: 26%; 
}
.new-chat-group:not(.newLeft) {
  left: 50%; 
}
.new-chat-group {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 80vh;
  background-color: var(--lightpink);
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  z-index: 9999;
  overflow-y: hidden;
  padding: 1rem;
}
.new-chat-group-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}
.new-chat-h2 {
  font-size: 1.2rem;
  font-weight: 600;
  height: 5%;
}
.new-user-group-name {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  height: 15%;
}
.new-user-group-name label {
  font-size: 0.9rem;
}
.new-user-group-name input {
  border-radius: 7px;
  padding: 0.6rem 1rem;
  outline: none;
  border: none;
  background-color: var(--dash-fourth);
}
.new-group {
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  overflow-y: scroll;
}
.new-chat {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}
.new-chat:hover {
  background-color: var(--dash-fourth);
}
.new-chat-name {
  display: flex;
  padding: 0.3rem 0;
  align-items: center;
  justify-content: space-between;
}
.new-chat-main {
  display: flex;
  gap: 1rem;
}
.new-chat-index {
  font-size: 0.9rem;
  font-weight: 500;
}
.create-group-btn {
  position: absolute;
  right: 3rem;
  top: 1rem;
  padding: 0.4rem 1rem;
  cursor: pointer;
  background-color: var(--dash-dark);
  color: var(--white);
  border: none;
  outline: none;
  border-radius: 5px;
}
.new-group-members {
  height: 30%;
  background-color: white;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  overflow-y: scroll;
}
.new-group-members-h3 {
  font-size: 0.7rem;
  font-weight: 600;
}
.new-group-member-container {
  padding: 0 0.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 2px;
}
.selected-member {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: var(--dash-fourth);
  border-radius: 20px;
  padding: 0.3rem 1rem;
}

.message-a { 
  color: var(--color);
}
.conversation-item.me .message-a { 
  color: var(--black1);
}
 
/* group details  */ 
/* **************************** */
/* **************************** */
.group-details {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 40vw;
  background-color: var(--white);
  border-left: 1px solid var(--color);
  right: -40vw; 
  z-index: 999;
  top: 0rem;
}
.group-details.open {
  animation: slideInRight 0.5s forwards; 
}
@keyframes slideInRight {
  from {right: -40vw;}
  to {right: 0;}
}
.group-details-top{ 
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.5px solid var(--light);
}
.arrow-back-icon{
  position: absolute;
  left: 1rem;
  top: 0rem;
  transform: scale(0.77);
  color: var(--black1);
  cursor: pointer;
}
.group-details-top-img { 
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}
.group-details-top-h4{ 
  font-size: 1.2rem;
  font-weight: 600;
}
.group-details-top-length{ 
  font-size: 0.95rem;
  font-weight: 500;
}
.group-details-center { 
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.group-details-center-btn { 
  padding: 0.4rem;
  display: flex;
  align-items: center;
  color: var(--black1);
  border-radius: 5px;
  outline: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
.group-details-center-add { 
  transform: scale(0.75);
  margin-right: 0.4rem;
}
.group-details-center-div { 
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.7rem;
}
.group-details-center-main { 
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.group-details-center-main-img{ 
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
.group-details-center-main-h4 { 
  font-size: 1rem;
  font-weight: 500;
}
.group-details-bottom { 
  gap: 0.7rem;
}
.group-btn-1 { 
  background-color: var(--message-bg3);
  color: var(--color);
  font-weight: 500;
}
.group-btn-2 { 
  background-color: var(--red);
  color: var(--color);
  font-weight: 500;
}
.group-details-edit-icon { 
  position: absolute;
  right: 1rem; 
  top: 0rem;
  color: var(--black1);
  transform: scale(0.68);
  cursor: pointer;
}

@media screen and (min-width: 1600px) {
  .chat-sidebar {
    width: 7rem;
  }
  .chat-sidebar-profile-dropdown {
    transform: scale(1.2);
  }
  .chat-sidebar-menu {
    gap: 2.5rem;
    margin-top: 3rem;
  }
  .chat-sidebar-menu > * > a,
  .chat-sidebar-menu > * > a::before,
  .content-message-name {
    font-size: 1.2rem;
  }
  .content-sidebar-input {
    padding: 16px 20px;
    font-size: 1.1rem;
  }
  .content-messages-list {
    padding: 1rem 0;
  }
  .content-messages-list > * > a {
    padding: 12px 0;
    margin-bottom: 1rem;
  }
  .content-message-image {
    width: 2.5rem;
    height: 2.5rem;
  }
  .content-message-text {
    font-size: 1rem;
  }
  .content-message-unread,
  .content-message-time,
  .conversation-user-status {
    font-size: 0.95rem;
  }
  .conversation-user-image {
    width: 4.5rem;
    height: 4.5rem;
  }
  .conversation-user-position {
    margin-left: 1.5rem;
  }
  .conversation-user-name {
    font-size: 1.5rem;
  }
  .conversation-divider {
    font-size: 1rem;
  }
  .conversation-divider span {
    display: inline-block;
    padding: 0.4rem 1rem;
    border-radius: 5px;
    background-color: var(--dash-fourth);
    position: relative;
    z-index: 1;
  }
  .conversation-item-image {
    width: 2rem;
    height: 2rem;
  }
  .conversation-item-text {
    font-size: 1.2rem;
  }
  .conversation-item-sender {
    font-size: 1.2rem;
  }
  .conversation-item-time {
    font-size: 1rem;
  }
  .conversation-item-dropdown-list li > div {
    font-size: 1.2rem;
  }
  .conversation-form {
    height: 5rem;
  }
  .conversation-form-group {
    height: 100%;
    gap: 1rem;
    margin: 5px 1rem;
    padding: 5px 24px;
  }
  .emoji-picker {
    left: 2rem;
    bottom: 6rem;
    width: 100%;
  }
  .conversation-form-input {
    font-size: 1.2rem;
  }
  .conversation-form-record {
    font-size: 3rem;
  }
  .new-chat-h2 {
    font-size: 1.5rem;
  }
  .new-user-group-name label {
    font-size: 1.2rem;
  }
  .new-user-group-name input {
    font-size: 1rem;
  }
  .new-chat-index {
    font-size: 1.2rem;
  }
  .create-group-btn {
    font-size: 1.2rem;
  }
  .new-group-members-h3 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 900px) {
  .group-details {
    max-width: 100vw;
    right: -100vw; 
  }
  @keyframes slideInRight {
    from {right: -100vw;}
    to {right: 0;}
  }
}

@media screen and (max-width: 768px) {
  .new-chat-group {
    width: 90vw;
    height: 80vh;
  }
  .new-chat-group.newLeft {
    left: 50%; 
  }
  .chat-container {
    flex-direction: column-reverse;
    padding: 0;
    border-radius: 0;
  }
  .chat-sidebar {
    padding: 0;
    top: unset;
    bottom: 0;
    width: 100%;
    height: 48px;
  }
  .chat-sidebar-logo {
    display: none;
  }
  .chat-sidebar-menu {
    flex-direction: row;
    padding: 0;
  }
  .chat-sidebar-menu > *,
  .chat-sidebar-menu > * > a {
    width: 100%;
    height: 100%;
  }
  .chat-sidebar-menu > * > a {
    padding: 8px;
  }
  .chat-sidebar-profile {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
  .chat-sidebar-profile-toggle {
    width: 1.5rem;
    height: 1.5rem;
  }
  .conversation-form-group {
    margin: 5px;
    padding: 0px 4px;
    border-radius: 5px;
  }
  .chat-sidebar-profile-dropdown {
    left: unset;
    right: 16px;
    z-index: 999;
  }
  .conversation,
  .chat-content {
    padding-left: unset;
    position: relative;
  }
  .content-sidebar {
    position: absolute;
    left: unset;
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .chat-sidebar-menu > * > a::before {
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    top: unset;
  }
  .chat-sidebar-menu > * > a:hover::before {
    bottom: calc(100% + 8px);
    left: 50%;
  }
  .chat-content {
    position: relative;
    height: calc(100% - 48px);
  }
  .conversation.active {
    position: relative;
    z-index: 20;
  }
  .conversation-back {
    display: flex;
  }
  .conversation-default.active {
    display: none;
    padding: 16px;
  }
  .conversation-top {
    padding: 0.5rem 0rem;
  }
  .conversation-main {
    padding: 0;
  }
}
