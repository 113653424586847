.dashMembers {
  padding: 1rem 1.2rem;
}

.dashMembers-container {
  padding: 1rem 1.2rem;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashMembers-dropdown {
  position: relative;
  display: inline-block;
}

.dashMembersDropBtn {
  cursor: pointer;
  border: none;
  transform: scale(0.85);
  color: var(--littleDark);
}  
.dashMembers-drop-content {
  display: none;
  position: absolute;
  right: 1.4rem;
  top: 0;
  background-color: #f1f1f1;
  min-width: 160px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.new-AContent {
  color: black;
  padding: 9px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
}

.new-AContent:hover {
  background-color: #ddd;
}

.dashMembers-drop-content:hover .dashMembersDropBtn {
  color: var(--dash-dark);
}

.btnContainer {
  display: flex;
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
}

.tabs {
  color: var(--dash-dark);
  background: none;
  margin: 4px 6px;
  border: none;
  width: 100%;
  height: 2.5rem;
  font: inherit;
  cursor: pointer;
  font-weight: 500;
  border-radius: 8px;
  position: relative;
}

.activeTab {
  border-radius: 8px 8px 0 0; 
  position: relative;
}

.activeTab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; 
  background-color: var(--dash-dark); 
  border-radius: 0 0 8px 8px; 
  transition: all 0.3s ease; 
}

.tabs:hover .activeTab::after {
  width: 90%; 
}

.tabData {
  margin-top: 10px;
  background-color: white;
  border-radius: 8px;
  max-width: 100%;
  padding-bottom: 10px;
}

.dataGrid {
  height: 70vh;
  margin-top: 1rem;
  box-shadow: var(--box-shadow1);
  border-radius: 10px;
}

.dataGrid::-webkit-scrollbar {
  display: flex;
  width: 2;
}

.dataGrid::-webkit-scrollbar-thumb {
  background-color: var(--dash-secondary); 
  border-radius: 4px; 
}

.dataGrid::-webkit-scrollbar-thumb:hover {
  background-color: var(--dash-tertiary); 
}

.comment-input, .comment-btn{
    flex-grow: 1;
    flex-shrink: 0;
}

.cellAction{
  display: flex;
  align-items: center;
  gap: 1rem;
  outline: none;
}

.viewButton{
  padding: 2px 5px;
  outline: none;
  border-radius: 5px;
  color: var(--dash-secondary);
  cursor: pointer;
  border: 1px solid var(--dash-secondary);
}

.activeButton{
  outline: none;
  padding: 2px 5px;
  border-radius: 5px;
  color: var(--green);
  cursor: pointer;
  border: 1px solid var(--green);
  background: rgba(32, 201, 10, 0.208);
}

.deleteButton{
  padding: 2px 5px;
  outline: none;
  border-radius: 5px;
  color: var(--red);
  background: rgba(201, 10, 10, 0.258);
  border: 1px solid var(--red);
  cursor: pointer;
}

.link-userpage-member{
  text-decoration: none;
  color: var(--dash-dark);
  font-weight: 500;
}

@media screen and (min-width: 1500px) {
  .dashMembers-container {
    padding: 1.2rem 1.5rem;
  }
  .tabs {
    height: 3.5rem;
    font-size: 1.3rem;  
  }
  .dash-btn {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 500px) {
  .dashMembers {
    padding: 1.2rem 0.65rem;
  }
  .dashMembers-container{
    padding: 0.7rem;
  }
}


