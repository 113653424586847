.register,
.login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.register-container,
.login-container,
.otp-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  padding: 2rem;
  height: 90vh;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.35);
  box-shadow: var(--box-shadow1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow-y: scroll;
}

.otp-container {
  width: 26rem;
}

.login-container {
  display: flex;
  padding: 0;
  gap: 1rem;
}

.login-photo {
  width: 50%;
  height: 100%;
}

.login-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.35);
}

.login-container-1 {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 2rem;
  width: 45%;
}

.register-title {
  color: var(--secondary);
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text-field-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.text-field {
  flex: 1 0 300px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.register-span {
  font-weight: 400;
  margin-bottom: 5px;
}

.register-input {
  position: relative;
  width: 100%;
  padding: 0.7rem 1rem;
  border: none;
  font-weight: 500;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  color: var(--white);
  background: var(--primary);
  letter-spacing: 0.5px;
}

.show-icon {
  position: absolute;
  right: 15px;
  color: var(--color);
  bottom: 0.15rem;
  cursor: pointer;
  transform: scale(0.75);
}

.registered-input-tnc {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.required-checkbox {
  cursor: pointer;
}
.register-tnc {
  color: var(--white);
  text-align: justify;
}

.register-tnc > span {
  color: var(--secondary);
  cursor: pointer;
}

.submit-btn {
  margin-top: 0rem;
  font-size: 15px;
  outline-width: 0;
  border-radius: 8px;
}

.sign_up {
  margin: 0.5rem 0;
  text-align: center;
  font-size: 1rem;
  color: var(--color);
}

.sign_up .link {
  color: var(--secondary);
  text-decoration: none;
  margin-left: 10px;
  font-weight: 600;
}

.sign_up .link:hover {
  text-decoration: underline;
}

.main_pass,
.pass {
  margin: 15px 0 0 5px;
  color: var(--secondary);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
}

/* OTP VERIFICATION */

#otp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#otp > div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.otp-input {
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  border: none;
  margin: 2rem 0;
}

.tnc-modal1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 111;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.register-tnc-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.tnc-close-icon {
  position: absolute;
  right: 0rem;
  top: 0;
  cursor: pointer;
  color: var(--red);
}

.tnc-modal-content1 {
  background-color: var(--color);
  padding: 2rem;
  width: 80%;
  height: 70%;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
}

.register-tnc-h4 {
  color: var(--black);
}


@media screen and (min-width: 1400px) and (max-width: 2000px) {
  .text-field {
    flex: 1 0 500px;
  }
}



@media screen and (min-width: 800px) and (max-width: 1000px) {
  .text-field {
    flex: 1 0 200px;
  }
}

@media screen and (max-width: 800px) {
  .login-container-1 {
    width: 100%;
  }
  .login-photo {
    display: none;
  }
}

@media screen and (max-width: 530px) {
  .register-container,
  .login-container {
    height: 100vh;
    width: 100vw;
    box-shadow: none;
  }
  #otp-container > .register-title {
    margin-top: 9rem;
  }
  .register-form {
    padding: 0 0.2rem;
  }
  .tnc-modal-content1 {
    width: 90%;
    height: 80%;
    padding: 1rem 0.8rem;
  }
}

@media screen and (max-width: 350px) {
  .register-container,
  .login-container {
    padding: 1rem;
  }
  .text-field {
    flex: 1 0 150px;
  }
  .login-container-1 { 
    padding: 1rem 0;
  }
}
