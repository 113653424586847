.userPage-container {
  padding: 1rem 1.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.userPage-container .dashboard-title,
.user-main {
  box-shadow: var(--box-shadow);
  padding: 1rem 2rem;
  border-radius: 7px;
}

.user-main {
  display: flex;
  gap: 2rem;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.user-edit-div {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.edit-mui-icon {
  color: var(--red);
}

.whatsapp-icon {
  background-color: var(--green);
  border-radius: 50%;
  color: var(--color);
  cursor: pointer;
}

.block-icon {
  color: var(--yellow);
  cursor: pointer;
}

.addNew-btn {
  color: var(--red);
  cursor: pointer;
}

.userImage {
  width: 10rem;
  height: 10rem;
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.block-icon-overlay {
  position: absolute;
  z-index: 3;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
}

.actual-block-icon {
  position: absolute;
  font-size: 50px;
  margin: 3rem 3rem 1rem 4rem;
  color: var(--red);
}

.block-icon-h3 {
  position: absolute;
  margin: 5rem 0 0 1.3rem;
  color: var(--color);
}

.user-index {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.user-wrap {
  display: flex;
  max-width: max-content;
  flex-wrap: wrap;
  gap: 1.4rem;
}

.user-title {
  color: var(--dash-dark);
  font-size: 1.2rem;
  font-weight: 500;
}

.user-value {
  color: var(--dash-primary);
  font-size: 1rem;
  font-weight: 500;
}

.user-documents {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  flex-wrap: wrap;
  border-radius: 10px;
}

.user-doc-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex: 1 0 300px;
}

.ahref {
  color: var(--dash-primary);
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
}

.comment-container {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  width: 100%;
}

.add-comment {
  display: flex;
  flex-direction: column;
}

.comment-h5 {
  font-size: 1rem;
}

.comment-form,
.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-container {
  margin: 1rem 0;
}

.filter-input,
.filter-select {
  flex: 1 0 130px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid #999;
  outline: none;
  width: 80%;
  font-weight: 500;
  cursor: pointer;
}

.comment-input,
.comment-btn {
  flex: 1 0;
}

.comment-input {
  background-color: var(--white);
  border: 1px solid #999;
  outline: none;
  width: 80%;
  font-weight: 500;
}

.addComment-btn {
  margin-top: 1rem;
}

.comment-form1 {
  width: 100%;
  padding: 1rem 0;
}

.call-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.call-fields {
  flex: 1 0 160px;
  display: flex;
  flex-direction: column;
}

.call-fields > label {
  color: var(--dash-dark);
  font-weight: 600;
}

.call-input-1 {
  display: flex;
  gap: 10px;
}

.call-select,
.call-input,
.call-comment {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  outline: 1px solid #999;
  margin-top: 0.5rem;
  border: none;
  cursor: pointer;
}

.show-comment-container-1 {
  display: flex;
  flex-direction: column;
}

.show-comment-header {
  background-color: white;
  margin-top: 1.5rem;
  box-shadow: var(--box-shadow);
  padding: 0.6rem 0.8rem;
  border-radius: 10px;
}

.call-addedBy {
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
}

.show-comments-value {
  color: var(--dash-primary);
}

.show-comment-header > .call-form > .call-fields {
  flex: 1 0 130px;
}

.show-comments-value {
  margin-top: 0.3rem;
}
.show-comment-value {
  margin-top: 0.8rem;
  font-size: 1rem;
}

/* SHOW PLAN MODAL ENTIRE CODE */

.tnc-modal1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 111;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
}

.tnc-modal-content1 {
  background-color: var(--lightpink);
  padding: 2rem;
  width: 80%;
  height: 70%;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.close-icon {
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  color: var(--red);
  border-radius: 50%;
}

.tnc-h4 {
  color: var(--dash-dark);
}

.showPlan-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
  overflow-y: scroll;
  gap: 1rem;
}

.showPlan-form-input {
  flex: 1 0 30%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.showPlan-form-input > label {
  font-weight: 600;
  color: var(--dash-dark);
}

.showPlan-input-field {
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 10px;
  border: 1px solid #999;
  outline: none;
}

/* show response modal css */
/* *************************************** */

.show-response-modal {
  overflow-y: auto;
  background-color: #f0f8ff;
}
.show-response-modal::-webkit-scrollbar {
  display: flex;
  width: 5px;
}
.show-response-modal::-webkit-scrollbar-thumb {
  background-color: var(--dash-dark);
  border-radius: 4px;
}
.show-response-modal::-webkit-scrollbar-thumb:hover {
  background-color: var(--dash-primary);
}
.show-response-modal-h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--black);
}
.show-response-modal-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  background-color: #fafafa;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
}
.show-response-modal-span {
  color: var(--black);
  font-size: 1rem;
  font-weight: 500;
}
.show-response-modal-div {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.show-response-modal-ul {
  list-style: none;
  font-size: 0.95rem;
  text-decoration: none;
  color: #444444;
}
.show-response-modal-li {
  font-size: 0.9rem;
  margin-top: 2px;
  color: var(--dash-primary);
}
.show-response-modal-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  gap: 1rem;
}
.show-response-modal-btn {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  padding: 0.25rem 0.7rem 0.25rem 0.1rem;
  outline: none;
  border: none;
  font-size: 0.75rem;
  cursor: pointer;
  background-color: var(--red);
  border-radius: 5px;
  color: var(--white);
}
.show-response-modal-btn:last-child {
  background-color: var(--green);
}
.show-response-modal-btn-icons {
  transform: scale(0.7);
}
.show-response-modal-input {
  flex-wrap: 1 0 20rem;
  padding: 0.3rem 1rem;
  width: 80%;
  border-radius: 5px;
  outline: 1px solid var(--lightgray);
  border: none;
}

/* ASSIGN TEST PAGE ENTIRE CSS */
/* ********************************** */
.assign-test-container1 {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.assign-test-input {
  flex: 1 0 20rem;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: 1px solid var(--lightgray);
  outline: none;
}
.assign-test-btn {
  flex: 1 0 5rem;
}
.assign-test-container2 {
  margin-top: 1rem;
  width: 100%;
}
.assign-test-h5 {
  font-weight: 500;
  font-size: 1rem;
  color: var(--black);
}
.assign-test-container2-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}
.assign-test-container2-div2 {
  display: flex;
  align-items: center;
}
.assign-test-container2-span {
  font-size: 0.9rem;
  font-size: 500;
  color: var(--primary);
  margin-right: 5px;
}
.assign-test-x-icon {
  margin-left: auto;
  transform: scale(0.65);
  color: var(--red);
  cursor: pointer;
}
.assign-test-btn1 {
  background-color: var(--green);
  color: var(--color);
  font-size: 0.9rem;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  margin-top: 0.5rem;
}
.view-btn{ 
  max-width: fit-content;
  margin-bottom: 1rem;
}
.dash-user-container-1{ 
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.dash-user-container-q{ 
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--black1);
}
.create-prescription-form { 
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}
.create-form-div { 
  display: flex;
  flex-direction: column;
  margin-top: 2px;
}
.create-form-label { 
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--black1);
}
.create-form-input { 
  border-radius: 5px;
  border: 1px solid var(--lightgray);
  padding: 0.34rem 1rem;
  width: 100%;
  margin-bottom: 1rem;
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .user-main {
    flex-direction: column;
    overflow-x: hidden;
  }
  .dashboard-title {
    font-size: 1rem;
  }
  .user-index,
  .user-documents {
    align-items: flex-start;
  }
  .userImage {
    width: 7rem;
    height: 7rem;
  }
  .user-title {
    font-size: 1rem;
  }
  .user-wrap {
    gap: 0px;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .user-main {
    gap: 1rem;
  }
  .userPage-container .dashboard-title,
  .user-main {
    padding: 0.65rem;
  }
  .user-doc-container {
    flex: 1 0 200px;
  }
  .tnc-modal-content1 {
    width: 90%;
    height: 80%;
    padding: 1rem 0.8rem;
  }
  .close-icon {
    right: 1rem;
    top: 1rem;
  }
  .showPlan-form-input {
    flex: 1 0 150px;
  }
  .assign-test-input {
    flex: 1 0 13rem;
  }
}

@media screen and (max-width: 500px) {
  .userImage {
    width: 6rem;
    height: 6rem;
  }
  .userPage-container {
    padding: 1rem 0.5rem;
  }
  .call-input-1 {
    flex-direction: column;
  }
  .block-icon-overlay {
    width: 6rem;
    height: 6rem;
  }
  .actual-block-icon {
    margin: 1rem 1rem 0rem 2rem;
  }
  .block-icon-h3 {
    margin: 3rem 0 0 0.7rem;
    font-size: 0.8rem;
  }
  .user-index {
    flex-direction: column;
    gap: 5px;
  }
  .user-title {
    font-size: 0.9rem;
  }
  .user-value {
    font-size: 0.8rem;
  }
  .user-documents {
    padding: 0.5rem;
  }
  .ahref {
    font-size: 0.8rem;
  }
  .user-doc-container {
    display: flex;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 150px;
  }
  .showPlan-form-input {
    flex: 1 0 100px;
  }
  .show-comment-value,
  .show-response-modal-ul {
    font-size: 0.8rem;
  }
  .show-response-modal-h4 {
    font-size: 1rem;
  }
  .show-response-modal-span {
    font-size: 0.95rem;
  }
}

@media screen and (min-width: 1500px) {
  .user-value {
    font-size: 1.4rem;
  }
  .user-title {
    font-size: 1.5rem;
  }
  .comment-h5 {
    font-size: 1.7rem;
  }
  .call-fields > label {
    font-size: 1.5rem;
  }
  .show-comments-value {
    font-size: 1.4rem;
  }
  .ahref {
    font-size: 1.4rem;
  }
  .call-addedBy {
    font-size: 1.7rem;
  }
  .user-doc-container {
    flex: 1 0 500px;
  }
  .tnc-h4 {
    font-size: 1.7rem;
  }
  .showPlan-form-input > label {
    font-size: 1.5rem;
  }
}
