.imageSlider {
  position: relative;
  margin-top: 4rem;
  padding: 0 2rem;
}

.image-title {
  font-size: 2rem;
  font-weight: 500;
  color: var(--secondary);
}

.image-desc {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0.5rem 0;
  color: var(--light);
}

.imageSlider-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.imageSlider-container::-webkit-scrollbar {
  display: flex;
  width: 2px;
}

.imageSlider-container::-webkit-scrollbar-thumb {
  background-color: var(--light); /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the thumb */
  cursor: pointer;
}

.imageSlider-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--color); /* Color of the scrollbar thumb on hover */
}

.imageSlider-card{
    flex: 0 0 auto;
    width: 220px;
    height: 100%;
    margin: 1rem 3rem 0 0;
}

.imageSlider-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
}

.imageSlider-image img:hover{
    transform: scale(1.02);
}

.imageSlider-details{
    width: 100%;
    height: 100px;
    padding-top: 10px;
}

.imageSlider-title{
    text-transform: uppercase;
}

.imageSlider-desc{
    color: var(--light);
    width: 100%;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    opacity: 0.9;
    text-transform: capitalize;   
    margin: 5px 0; 
}

@media screen and (max-width: 768px) {
    .image-title {
       font-size: 1.5rem;
    }
    .imageSlider {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 400px) {
    .image-title {
       font-size: 1.3rem;
    }
    .image-desc {
        font-size: 1rem;
    }
    .imageSlider-card{
        width: 170px;
    }
    .imageSlider-title {
        font-size: 1rem;
    }
    .imageSlider-desc {
        font-size: 16px;
    }
}
