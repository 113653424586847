.faq-card {
  height: 55%;
  width: 35%;
  background-color: white;
  border-radius: 10px;
  position: fixed;
  box-shadow: var(--box-shadow);
  padding: 0.75rem;
  overflow: hidden;
  z-index: 999;
  bottom: 5rem;
  right: 3rem;
}
.faq-header {
  display: flex;
  padding-bottom: 0.31rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--dash-dark);
}
.faq-h4 {
  color: var(--dash-dark);
  font-size: 0.95rem;
  font-weight: 600;
}
.faq-close-icon {
  color: var(--red);
  transform: scale(0.85);
  cursor: pointer;
}
.faq-section {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 0.3rem;
  max-height: 70%; 
  overflow-y: scroll;
}

.faq-no-section {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
  padding-right: 0.3rem;
  max-height: 70%; 
}

.faq-section::-webkit-scrollbar {
  display: flex;
  width: 6px;
}

.faq-section::-webkit-scrollbar-thumb {
  background-color: var(--dash-primary);
  border-radius: 4px;
}

.faq-section::-webkit-scrollbar-thumb:hover {
  background-color: var(--dash-secondary);
}

.faqMain {
  margin: auto;
  width: 100%;
  border-radius: 0.5rem;
  position: relative;
}

.question-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.question-main {
  display: flex;
  align-items: flex-start;
  gap: 0.3rem;
}

.question-h3 {
  color: var(--dash-dark);
  font-size: 0.9rem;
}

.question {
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--dash-dark);
  padding: 0 1rem;
}

.question-box .i {
  font-size: 1.5rem;
  text-align: center;
  color: var(--green);
  transition: 0.2s;
}

.answer-box {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.5s;
}

.answer {
  overflow: hidden;
  color: var(--dash-primary);
  font-weight: 600;
  padding: 0 2rem;
  font-size: 0.9rem;
}

.faqMain.active {
    background-color: var(--dash-background);
    height: 100%;
}

.faqMain.active .answer-box {
  padding: 1rem 0;
  grid-template-rows: 1fr;
}

.faqMain.active .question-box .i {
  transform: rotate(45deg);
}

#input-section {
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  max-height: 20%;
  width: 95%;
  z-index: 4;
}

#input-section input {
  min-width: 0.5vw;
  outline: none;
  height: 3vw;
  width: 100%;
  padding: 0 0.2rem;
  border: none;
  background: transparent;
  border-bottom: 1px solid var(--dash-dark);
}
.send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 0;
  outline: none;
}
.send-icon {
  transform: scale(0.75) rotate(-30deg);
  color: var(--lightgray);
}

.no-faqs {
  font-size: 1rem;
  color: var(--dash-dark);
}

.talk-faq {
  font-size: 0.8rem;
  color: var(--light);
}

@media screen and (max-width: 768px) {
    .faq-card {
        width: 60%;
        bottom: 6rem;
      }
}

@media screen and (max-width: 480px) {
    .faq-card {
        width: 80%;
        right: 0.7rem;
        padding: 5px;
    }
    #input-section {
    width: 80%;
    gap: 10px;
  }
  .send-icon {
    margin-top: -1vw;
  }
  .chatbot-h4 {
    font-size: 12px;
  }
}
