.testimonial {
  margin-top: 5rem;
}

.testimonial-header {
  display: flex;
  gap: 2rem;
}

.testimonial-header .svg-left,
.testimonial-header .svg-right {
  width: 2rem;
  height: 2.5rem;
  fill: var(--secondary);
  cursor: pointer;
  margin-top: 5rem;
  z-index: 1;
}

.testimonial-card {
  width: 100%;
  display: flex;
  gap: 3rem;
  align-items: center;
  transition: transform 0.5s ease-in-out;
}

.testimonial-card.transition {
  transform: translate(30px);
}

.testimonial-image {
  width: 15rem;
  height: 15rem;
  overflow: hidden;
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-title {
  font-size: 1.6rem;
  font-weight: 500;
}

.testimonial-desc {
  color: var(--light);
  margin-top: 1rem;
  text-align: justify;
}

@media screen and (max-width: 786px) {
  .testimonial-container {
    flex-direction: column;
    gap: 1rem;
  }
  .testimonial-card {
    flex-direction: column;
  }
  .testimonial-image {
    width: 12rem;
    height: 12rem;
  }
}

@media screen and (max-width: 400px) {
  .testimonial .website-header {
    font-size: 1.2rem;
  }
  .testimonial-header {
    gap: 1rem;
  }
  .testimonial-image {
    width: 10rem;
    height: 10rem;
  }
  .testimonial-content {
    font-size: 1.3rem;
    font-weight: 500;
  }
  .testimonial-header .svg-left,
  .testimonial-header .svg-right {
    width: 2rem;
    height: 2rem;
  }
}
