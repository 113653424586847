.couponCode {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.generator {
  box-shadow: var(--box-shadow);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
}

.code-form,
.main-generator {
  flex-grow: 1;
  flex-shrink: 0;
  width: 40%;
}

.code-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.code-input {
  padding: 0.4rem 0.6rem;
  background-color: var(--lightpink);
  outline: none;
  color: var(--black);
  border-radius: 5px;
  border: none;
}

.tnc {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.tnc-container,
.tnc-container-1 {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 1rem;
  font-size: 1.2rem;
}

.tnc-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tnc-form-field > .add-tnc {
  width: 100%;
  padding: 0.4rem 1rem;
  color: var(--black);
  background-color: var(--lightpink);
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
}

.tnc-btn {
  width: max-content;
}

.tnc-title {
  color: var(--dash-dark);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.faqMap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.4rem;
  padding: 0.6rem;
  border-radius: 10px;
  font-size: 1rem;
  color: var(--dash-dark);
  background-color: var(--lightpink);
}

.faqMap-container {
  display: flex;
  gap: 1rem;
}

.faq-tnc {
  align-items: center;
}

.faqmap-answer {
  color: var(--dash-primary);
  font-weight: 600;
}

.delete-icon {
  color: var(--red);
  cursor: pointer;
}

.second-coupon {
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
}

.coupon-product-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.2rem;
  border-radius: 10px;
}

.coupon-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lightpink);
  padding: 1rem;
  border-radius: 10px;
  position: relative;
}

.coupon-main-1 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.show-coupon-title {
  flex: 1 0 200px;
  font-size: 0.8rem;
  font-weight: 600;
}

.show-coupon-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.show-coupon-button > button {
  cursor: pointer;
  color: var(--red);
  outline: none;
  border: none;
}

/* GOOGLE DOCS CONTAINER - ENTIRE CSS */

.admin-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
}
.admin-top{ 
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .3rem;
}
.admin-top-title { 
  color: var(--black);
  font-weight: 500;
  font-size: 0.95rem;
}
.admin-my-forms {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 3rem;
}
.admin-my-forms-container {
  flex: 1 0 250px;
  max-width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 7px;
  background-color: var(--lightpink);
}
.admin-my-form-1 {
  width: 100%;
  height: 200px;
  border-radius: 7px;
}
.admin-my-form-1-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}
.admin-my-form-2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem; 
}
.admin-my-form-2-left {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--black);
}

/* dash user page css */
/* ************************************ */
/* ************************************ */
.dash-user { 
  background-color: var(--white);
  border-radius: 5px;
  padding: 1rem;
  box-shadow: var(--box-shadow);
}
.dash-user-container-h1{ 
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--black1);
  margin-bottom: 0.2rem;
}
.dash-user-container-h4,
.spare-span { 
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--black);
  margin-bottom: 2rem;
}
.spare-span { 
  font-size: 1.1rem;
  font-weight: 600;
}
.dash-user-container { 
  background-color: var(--lightpink);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border-radius: 5px;
}
.dash-user-container-h4, 
.dash-user-container-h2, 
.dash-user-container-h3{ 
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  color: var(--black);
}
.dash-user-container-p, 
.dash-user-container-li{ 
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--black);
  margin-bottom: 0.3rem;
}
.btn-fill-form { 
  margin: 5px 0;
}
.dash-user-container-ul{ 
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

@media screen and (max-width: 900px) {
  .admin-my-forms-container {
    max-width: calc(100% / 2);
  }
  
}

@media screen and (max-width: 600px) {
  .admin-my-forms-container {
    max-width: 100% ;
  }
  .dash-user { 
    padding: 0.5rem;
  }
  .dash-user-container-h1{ 
    font-size: 1.1rem;
  }
  .dash-user-container-h4,
  .spare-span { 
    font-size: 1rem;
  }
  .dash-user-container { 
    padding: 0.5rem;
  }
  .dash-user-container-h4, 
  .dash-user-container-h2, 
  .dash-user-container-h3{ 
    font-size: 0.95rem;
  }
  .dash-user-container-p, 
  .dash-user-container-li{ 
    font-size: 0.9rem;
  }
  .dash-user-container-ul { 
    margin-left: 0.5rem;
  }
  
}


@media screen and (max-width: 400px) {
  .generator {
    padding: 0;
  }
  .coupon-product-container {
    padding: 1rem 0;
  }
  .faqMap {
    font-size: 0.8rem;
  }
  .admin-my-forms-container {
    flex: 1 0 170px;
  }
}
