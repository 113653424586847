.products {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 100%;
}

.products .website-header {
  font-size: 1.5rem;
}

.no-product-div {
  display: grid;
  place-items: center;
  width: 95vw;
  margin-right: -3rem;
}
.empty-product-h3 {
  font-weight: 500;
  font-size: 1rem;
}

.empty-product-h6 {
  font-weight: 400;
  font-size: 0.9rem;
  color: var(--light);
}

.product-p {
  font-size: 1.2rem;
  text-align: center;
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.product {
  width: 100%;
  padding: 10px 12px;
  background-color: var(--littleDark);
  border: 1px solid #999;
  border-radius: 20px;
  margin: 1rem 0;
  transition: 0.2s ease;
  position: relative;
  cursor: pointer;
}

.product:hover {
  box-shadow: rgba(255, 255, 255, 0.25) 0px 14px 28px,
    rgba(255, 255, 255, 0.4) 0px 10px 10px;
}

.productImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.product-description {
  text-align: start;
  padding: 10px 0;
}

.product-description span {
  color: var(--secondary);
  font-size: 0.8rem;
  font-weight: 700;
}

.product-name {
  padding-top: 7px;
  color: var(--color);
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.product-description .icon {
  font-size: 12px;
  color: rgb(243, 183, 29);
}

.product-price {
  padding-top: 7px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color);
}

.shopping-icon {
  fill: var(--color);
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

/* SINGLE PRODUCT PAGE */

.single-products {
  display: flex;
  padding: 2rem;
  gap: 3rem;
  overflow: hidden;
  width: 100%;
}

.single-product-images {
  display: flex;
  gap: 1rem;
}

.single-main-image {
  height: 450px;
  width: 400px;
  border-radius: 20px;
  object-fit: cover;
}

.single-image-grp {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.single-image-col > img {
  cursor: pointer;
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.single-products-details .product-brand {
  color: var(--secondary);
  padding-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.single-products-details .product-name {
  font-size: 1.3rem;
}

.single-products-details .stars .icon {
  fill: var(--secondary);
  margin-top: 3px;
}

.single-products-details .product-price {
  margin-bottom: 1rem;
  color: var(--color);
}

.single-products-details select {
  display: block;
  padding: 0.4rem 0.7rem;
  margin-bottom: 0.7rem;
  background: transparent;
  border-radius: 5px;
  outline: 1px solid #999;
  color: var(--color);
  cursor: pointer;
}

.single-products-details option {
  color: black;
  text-transform: capitalize;
}

.single-products-details .add-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}

.single-products-details .add-div input {
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 10px;
  font-size: 16px;
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  outline: 1px solid var(--color);
  background: transparent;
  color: var(--color);
  border-radius: 5px;
}

.single-products-details .product-d {
  color: var(--secondary);
  margin-top: 3rem;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.single-products-details .product-detail {
  color: var(--light);
  text-align: justify;
}

/* programs */

.event-btn {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0rem;
  border-radius: 10px;
  outline: none;
  border: none;
  color: var(--color);
  background-color: var(--secondary);
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .products-container {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}

@media screen and (max-width: 950px) {
  .products-container {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  }
  .products {
    padding: 0 1rem;
  }
  .single-products {
    display: flex;
    flex-direction: column;
  }
  .single-product-images {
    width: 100%;
  }
  .single-products-details {
    width: 100%;
  }
  .single-products-details .product-brand {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .productImage {
    height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .single-product-images {
    width: 100%;
    flex-direction: column;
  }
  .single-product-images .single-main-image {
    width: 100%;
  }
  .single-image-grp {
    flex-direction: row;
  }
  .single-image-col > img {
    width: 100%;
    height: 70px;
  }
  .single-products {
    padding: 1rem;
    gap: 1rem;
  }
  .single-main-image {
    height: 250px;
  }
  .single-products-details .stars .icon {
    width: 1rem;
  }
  .single-products-details select {
    width: 50%;
  }
  .single-products-details .product-detail {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .products-container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

@media screen and (max-width: 400px) {
  .products .website-header {
    font-size: 1.2rem;
  }
  .product-p {
    font-size: 1rem;
  }
  .product {
    width: 100%;
    min-width: 100%;
  }
}
