.dashLayout {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
}
.dashLayout-container {
  flex: 6;
  position: relative;
  overflow-y: auto;
}
.chatbot-container {
  position: fixed;
  right: 4vw;
  bottom: 8vh;
  color: var(--dash-fourth);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--green);
  box-shadow: var(--box-shadow);
  z-index: 1;
  transform: scale(.85);
}
.chatbot-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 0 0 11px;
}

@media screen and (max-width: 768px) {
  .chatbot-container {
    right: 7vw;
    bottom: 10vh;
  }
}

