.singleData {
    position: relative;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    margin: 1rem 1.2rem;
    padding: 1rem 1.2rem;
}

.editButton{
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    padding: 0.3rem 1rem;
    font-weight: 400;
}

.singleItem{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.singleItemImage{
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
}

.singleItemDetails{
    padding: 0 2rem;
}

.singleItemName{
    margin-bottom: 2rem;
    color: var(--dash-dark);
    text-transform: capitalize;
    font-weight: 500;
}

.itemDetails{
    margin-bottom: 12px;
    font-size: 0.8rem;
    display: flex;
    gap: 0.3rem;
}

.itemKey{
    font-weight: 600;
    font-size: 1rem;
    margin-right: 5px;
    color: var(--dash-dark);
}

.itemValue{
    color: var(--dash-primary);
    font-size: 1rem;
    font-weight: 500;
}

.profile-main{
    margin: 0 1.2rem;
}

/* SINGLE PROFILE */

.newContainer .new-top, .newContainer .new-bottom{
    box-shadow: var(--box-shadow);
    margin: 1rem 1.2rem;
    padding: 1rem 1.2rem;
    display: flex;
    border-radius: 10px;
}

.newContainer .new-bottom .new-right{
    width: 100%;
}

.newContainer .new-bottom .new-right form {
    display: flex;
    flex-wrap: wrap;
    gap: 1.8rem;
    justify-content: space-around;
}

.newContainer .new-bottom .new-right form .forminput{
    width: 40%;
}

.newContainer .new-bottom .new-right form .new-left  img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
}

.newContainer .new-bottom .new-right form .images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.newContainer .new-bottom .new-right form .forminput label{
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--dash-dark);
    font-size: 1rem;
    font-weight: 600;
}

.newContainer .new-bottom .new-right form .forminput label .icon{
    cursor: pointer;
}

.newContainer .new-bottom .new-right form .forminput input, .newContainer .new-bottom .new-right form .forminput select, .newContainer .new-bottom .new-right form .forminput option {
    width: 100%;
    padding: 0.2rem 0;
    outline: none;
    border: none;
    border-bottom: 1px solid #555;
    color: var(--dash-primary);
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}

.form-input-changer{
    width: 92%;
    border-bottom: 1px solid var(--dash-dark);
}

.form-password{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.newContainer .new-bottom .new-right form .forminput input::placeholder{
    font-size: 0.9rem;
    opacity: 0.5;
}

.newContainer .new-bottom .new-right form > button{
    width: 90%;
}



@media screen and (max-width: 500px) {
    .singleItem {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }
    .newContainer .new-bottom .new-right form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .newContainer .new-bottom .new-right form .forminput{
        width: 100%;
    }
    .singleItemDetails {
        padding: 0 0.2rem;
        overflow-x: hidden;
    }
    .itemTitle {
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
    }
    .editButton {
        font-size: 0.8rem;
    }
    .itemKey{
        font-size: 0.9rem;
    }
    .itemValue{
        font-size: 0.8rem;
    }
    .singleItemName {
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
    }
}


@media screen and (min-width: 1500px) {
    .itemTitle {
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
    }
    .dash-title{
        font-size: 1.2rem;
    }
    .editButton {
        font-size: 1.2rem;
    }
    .itemKey{
        font-size: 1.5rem;
    }
    .itemValue{
        font-size: 1.4rem;
    }
    .singleItemName {
        font-size: 1.3rem;
    }
    .newContainer .new-bottom .new-right form .forminput label{
        font-size: 1.4rem;
    }    
    .newContainer .new-bottom .new-right form .forminput input, .newContainer .new-bottom .new-right form .forminput select, .newContainer .new-bottom .new-right form .forminput option {
        font-size: 1.2rem;
    }
    
}
