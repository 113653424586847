#mobile {
  display: none;
  width: 2rem;
  position: absolute;
  right: 2rem;
  cursor: pointer;
}

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  padding: 1rem 2rem 0 2rem;
  position: relative;
}

.Navbar .pm-logo {
  font-family: "Comforter";
  font-size: 2rem;
}

.Navbar .menu {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.Navbar .menu .nav-ul {
  display: flex;
  width: 100%;
  gap: 3rem;
  list-style: none;
  font-size: 1.1rem;
  position: relative;
  cursor: pointer;
}

.Navbar .menu .nav-ul .navlink,
.navlink {
  text-decoration: none;
  color: var(--color);
  position: relative;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.Navbar .menu .nav-ul .navlink:hover {
  color: var(--secondary);
  transition: 0.2s ease-in-out;
}

.Navbar .menu .nav-ul .navlink::after {
  background-color: var(--primary);
  bottom: -2px;
  content: "";
  display: block;
  height: 1px;
  background: var(--secondary);
  position: absolute;
  width: 0;
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
}

.Navbar .menu .nav-ul .navlink:hover::after {
  width: 70%;
  left: 0;
  transition: 0.5s ease-in-out;
}

.Navbar .menu .nav-ul .navlink:hover .down-icon {
  transform: rotate(180deg);
  transition: 0.3s all;
}

.btn-login {
  padding: 0.2rem 1.2rem;
  outline-width: 0;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--primary);
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .something {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-content .something:hover {
  background-color: var(--secondary);
}

.dropdown-content a {
  color: var(--color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content ul.nested-dropdown-content {
  display: none;
  background-color: var(--primary);
  position: absolute;
  top: 0;
  list-style: none;
  left: 100%;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 0.5rem;
}

.dropdown-content .something:hover + ul.nested-dropdown-content,
.dropdown-content ul.nested-dropdown-content:hover {
  display: block;
}

.dropdown-content ul.nested-dropdown-content .some:hover {
  background-color: var(--secondary);
}
.some {
  padding: 0.6rem 1rem;
  color: var(--color);
}

@media screen and (max-width: 1200px) {
  #mobile {
    display: block;
    z-index: 19;
  }
  #mobile > .burger {
    fill: var(--secondary);
  }
  .Navbar {
    position: relative;
    width: 100%;
  }
  .Navbar .menu {
    flex: initial;
    width: 100%;
    padding-top: 3rem;
    position: fixed;
    z-index: 20;
    left: -100%;
    overflow-x: hidden;
    overflow-y: auto;
    top: 1px;
    background-color: var(--black);
    height: 100vh;
    border-radius: 0px 20px 20px 0;
    transition: 0.3s ease-in-out;
  }
  .menu.active {
    left: 0;
    width: 70%;
  }
  .Navbar .nav-ul {
    flex-direction: column;
    font-size: 1.1rem;
    overflow-x: hidden;
    padding: 0 2rem;
  }
  .btn-login {
    margin-right: 3rem;
  }
  .dropdown:focus .dropdown-content {
    display: block;
  }

  .dropdown .dropdown-content,
  .dropdown-content ul.nested-dropdown-content {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    opacity: 1;
    background-color: var(--primary);
    pointer-events: auto;
    width: 100%;
    padding: 0;
  }
  .dropdown-content .something:focus + ul.nested-dropdown-content,
  .dropdown-content ul.nested-dropdown-content:focus {
    display: block;
  }

  .something:focus .dropdown-content ul.nested-dropdown-content {
    display: flex;
    flex-direction: column;
  }
  .navlink:nth-child(1):focus .dropdown-content,
  .dropdown-content .something:focus ul.nested-dropdown-content {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .Navbar {
    padding: 0.5rem 1rem 0 1rem;
  }
  .Navbar .pm-logo {
    font-size: 1.7rem;
  }
  #mobile .burger {
    width: 1.7rem;
    margin-top: 5px;
  }
  .btn-login {
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.3rem 1.5rem;
    margin-right: 4rem;
  }
}

@media screen and (max-width: 320px) {
  #mobile {
    width: 1rem;
    right: 1rem;
  }
  .Navbar {
    padding: 0.3rem 1rem 0 1rem;
  }
  .Navbar .pm-logo {
    font-size: 1.2rem;
  }
  #mobile .burger {
    width: 1.5rem;
    margin-top: 5px;
  }
}

@media screen and (max-width: 320px) {
  .dropdown .d-btn {
    margin-left: -42px;
  }
}
