.plansPage {
  padding: 0 2rem;
  width: 100%;
  height: 100%;
}
.plansPage .website-header {
  font-weight: 500;
  font-size: 1.2rem;
}
.plansPage-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}
.plansPage-title {
  width: 100%;
  border-bottom: 2px solid var(--color);
  color: var(--secondary);
  font-weight: 500;
}
.plansPage-slider {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  overflow: hidden;
  transition: transform 0.5s ease;
}
.plansCard {
  width: 30vw;
  min-width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: var(--black1);
  box-shadow: var(--box-shadow);
  outline: none;
  border: 1px solid #999;
  position: relative;
  transition: 0.3s ease-in-out all;
  height: 290px;
}
.plansCard:hover {
  box-shadow: rgba(255, 255, 255, 0.25) 0px 7px 2px,
    rgba(255, 255, 255, 0.4) 0px 10px 10px;
}
.plans-slide-btn {
  font-size: 2rem;
  background-color: var(--white);
  color: var(--secondary);
  border-radius: 50%;
  cursor: pointer;
}
.decrease-plan {
  position: absolute;
  left: 0;
  transform: rotate(180deg);
  top: 45%;
}
.increase-plan {
  position: absolute;
  right: 0;
  top: 45%;
}
.plansCard-h4 {
  font-size: 1.2rem;
  color: var(--secondary);
  display: flex;
  font-weight: 600;
  text-transform: capitalize;
}
.plansCard-h3 {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color);
  margin-bottom: 1rem;
}
.plansCard-select {
  width: 100%;
  padding: 0.3rem 1rem;
  border: none;
  outline: 1px solid #999;
  color: var(--color);
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
  margin-bottom: 1rem;
}
.plansCard-option {
  background-color: var(--littleDark);
  color: var(--color);
  border: none;
  outline: none;
  cursor: pointer;
}
.plansCard-ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
}
.plansCard-li {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: var(--color);
}
.plansCard-li::before {
  content: "✓";
  margin-right: 5px;
}
.plansCard .send-btn {
  position: absolute;
  bottom: 1rem;
  width: 88%;
  border-radius: 8px;
}
@media screen and (max-width: 400px) {
  .plansPage {
    padding: 0 1rem;
  }
}
