.modal1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 111;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
}

.modal-content1 {
  background-color: var(--dash-background);
  box-shadow: var(--box-shadow);
  padding: 3rem;
  width: 50%;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
}

.delete-para {
  margin-top: -2rem;
  font-size: 1rem;
  color: var(--black);
  font-weight: 600;
}

.delete-button {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.modal-mui {
  margin-top: 1px;
  height: 4rem;
  width: 2rem;
  color: var(--green);
}

.btn1,
.btn2 {
  background-color: var(--red);
  color: var(--color);
  padding: 0.5rem 1.2rem;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn2 {
  background-color: var(--dash-dark);
  color: var(--color);
  font-weight: 600;
}

/* CURRENT PLAN CSS */

.accordion {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accordion-span { 
  padding: 0.3rem 0.5rem;
  color: var(--dash-dark);
  font-size: 0.75rem;
  font-weight: 600;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 0.55rem 1rem;
  width: 100%;
  border: none;
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.accordion-header.active {
  background-color: var(--color);
}

.accordion-header.active i {
  transform: rotate(180deg);
  transition: 1s  ease-in-out;
}

.accordion-body {
  max-height: 0;
  margin-top: 0.4rem;
  background-color: var(--color);
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.accordion-body.active {
  max-height: 100%;
  transition: .5s ease-in-out;
}

/* current plans css */

.currentPlans{
    width: 100%;
    border-radius: 10px;
    padding: 0.2rem;
}

.currentPlans-container{
    position: relative;
    padding: 0.5rem;
    background-color: var(--white);
    border-radius: 10px;
    margin-top: 1rem;
}

.currentPlans-h4{
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--dash-dark);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.currentPlans-h4 > .color-changer {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
}

/* Status Colors */
.status-active {
    background-color: green;
}

.status-expired {
    background-color: orange;
}

.status-transferred {
    background-color: blue;
}

.status-freezed {
    background-color: brown;
}

.status-upcoming {
    background-color: yellow;
}

.status-terminated {
    background-color: red;
}

.status-upgrade {
    background-color: violet;
}


.currentPlan-details{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.currentDetails-container{
    flex: 1 0 200px;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.currentPlan-key{
    color: var(--dash-dark);
    font-size: 1rem;
    font-weight: 600;
}

.currentPlan-value{
    color: var(--dash-primary);
    font-size: 0.9rem;
    font-weight: 600;
}

.editIcon{
    position: absolute;
    right: 1rem;
    top: 0rem;
}

.additional-buttons{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
}

.additional-buttons > .dash-btn{
    flex: 1 0 200px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 56px;
}


@media screen and (max-width: 500px) {
  .modal-content1 {
    background-color: var(--dash-background);
    box-shadow: var(--box-shadow);
    padding: 1rem;
    padding-top: 3rem;
    width: 80%;
    border-radius: 10px;
    position: relative;
    overflow-y: scroll;
  }
}

@media  screen and (min-width: 1500px) {
  .accordion-span {
    font-size: 1.3rem;
  }
  .accordion-header {
    padding: 0.8rem;
  }
  .currentDetails-container {
    flex: 1 0 300px;
  }
  .currentPlan-key {
    font-size: 1.5rem;
  }
  .currentPlan-value {
    font-size: 1.3rem;
  }
  .currentPlans-h4 {
    font-size: 1.4rem;
  }
  .currentPlans-h4 > .color-changer {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
  }
}
