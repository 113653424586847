@import url('https://fonts.googleapis.com/css2?family=Comforter&family=Signika:wght@300;400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Signika", sans-serif;
  background-color: var(--primary);
  color: var(--color);
}

/* text-slate-200: #cbd5e0
text-slate-300: #a0aec0
text-slate-400: #718096
text-slate-500: #4a5568
text-slate-600: #2d3748
text-slate-700: #1a202c
text-slate-800: #171923
text-slate-900: #0a0f15 */

:root{
  --primary: #121212;
  --secondary: #df8b0e;
  --white: #ffffff;
  --color: #f4f4f4;
  --light: #d8c2c2;
  --littleDark: #1b1b1b;
  --black: #0e0d0d;
  --black1: #292424;
  --green: rgb(5, 180, 5);
  --red: red;
  --lightgray: #585858;
  --lightpink: rgb(240, 240, 240);
  --box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
  --box-shadow1: 1px 1px 7px 1px rgba(255, 255, 255, 0.21);
  --dash-dark: #022B42;
  --dash-primary : #395886;
  --dash-secondary: #638ecb;
  --dash-tertiary: #8aaee0;
  --dash-fourth: #d5deef;
  --dash-penta: #b1c9ef;
  --dash-gray: rgb(202, 174, 174);
  --dash-background: #f0f3fa;
  --message-bg: #e4e8ec;
  --message-bg1: #c1c7cf;
  --message-bg2: #fffffc;
  --message-bg3: #8589c4;
}

::-webkit-scrollbar {
  display: flex;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color:var(--lightgray); 
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--dash-gray); 
}

.btn{
  background-color: var(--secondary);
  color: var(--color);
  padding: 0.6rem 1.5rem;
  border: 4px solid transparent;
  border-radius: 15px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.loader-position{
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
}

.btn:hover{
  color: var(--secondary);
  background-color: var(--color);
  transition: 0.4s ease-in-out;
}

.send-btn{
  background-color: var(--secondary);
  color: var(--color);
  padding: 0.6rem 2rem;
  outline-width: 0;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1rem;
  transition: .2s ease-in-out;
}

.send-btn:hover{
  background-color: #f48c06;
}

.dash-btn {
  padding: 0.45rem 1.2rem;
  background-color: var(--green);
  color: var(--color);
  font-size: 0.95rem;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.dash-btn:hover{
  background-color: #10ee10;
}

.loader-css{
  transform: scale(0.78);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}


.Link {text-decoration: none;}

.svg{width: 3rem;}

.website-header{
  color: var(--secondary);
  font-size: 2rem;
  display: grid;
  place-items: center;
  margin: 1rem 0;
}

.dashboard-title{
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--dash-dark);
}

.dashboard{
  background-color: white;
  position: relative;
  color: var(--dash-dark);
}

@media screen and (min-width: 1500px) {
  .dashboard-title {
    font-size: 1.6rem;
  }
}