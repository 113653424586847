.details {
  margin-top: 5rem;
  background-color: var(--black);
}

.figures {
  display: flex;
  justify-content: space-around;
  margin-top: 7rem;
}

.figures > div {
  margin-top: 2rem;
}

.details-span {
  font-size: 2rem;
}

.figures > div:nth-child(3) {
  width: 15rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.figures > div:nth-child(3) img {
  width: 100%;
  border-radius: 50%;
  border: 5px solid var(--secondary);
  padding: 2px;
  margin-top: -8rem;
}

.details-title {
  color: var(--secondary);
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 400;
}

.marquee-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  overflow-x: hidden;
  height: 4rem;
  gap: 2rem;
  margin-top: 1rem;
  padding: 1rem 0;
}

.marquee {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  white-space: nowrap;
  animation: marquee 18s infinite linear;
}

.marquee:after {
  content: "  PERFORMANCE AND FITNESS CENTER . EXERCISE TESTING . EXERCISE CONSULTATION . SPORTS AND EXERCISE SCIENCE . CUSTOMIZE WORKOUT PLANS . STRENGTH AND CONDITIONING . ";
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 768px) {
  .details {
    margin-top: 3rem;
  }
  .figures {
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    position: relative;
  }
  .figures > div:nth-of-type(3) {
    margin-top: 6rem;
  }
  .figures > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
  .figures > div:nth-child(3) {
    display: flex;
    justify-content: center;
    margin-left: 32%;
    background: none;
  }
  .figures > div:nth-child(3) img {
    margin-top: -4rem;
    width: 10rem;
  }
  .figures > div > span {
    font-size: 2rem;
  }
  .marquee-container {
    height: 4rem;
  }
  .marquee {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  .figures {
    padding-left: 0;
    margin-top: 0;
    gap: 0;
  }
  .figures > div:nth-child(3) {
    margin-left: 25%;
  }
  .bmi-form {
    padding: 1rem 1rem;
  }
  .figures > div > span {
    font-size: 1.5rem;
  }
  .marquee {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .figures > div:nth-child(3) {
    margin-left: 27%;
  }
}

@media screen and (max-width: 400px) {
  .figures > div:nth-child(3) {
    margin-left: 17%;
  }
}

@media screen and (max-width: 350px) {
  .figures > div:nth-child(3) {
    margin-left: 13%;
  }
}

@media screen and (max-width: 260px) {
  .figures > div:nth-child(3) {
    margin-left: 5%;
  }
}
