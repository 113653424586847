.bmi-page {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  padding: 0 2rem;
  gap: 4rem;
}

.glass,
.bmi-right {
  flex-grow: 1;
  flex-shrink: 0;
}

.glass {
  width: 35%;
  background: rgba(71, 63, 63, 0.47);
  border-radius: 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  border: 1px solid rgba(71, 63, 63, 0.57);
  height: 420px;
}

.bmi-right {
  width: 45%;
  height: 420px;
}

.bmi-right > img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.bmi-form {
  padding: 1rem 1.5rem;
}

.bmi-h2 {
  color: var(--secondary);
  font-weight: 600;
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.bmi-h5 {
  color: var(--color);
  font-size: 2rem;
  display: flex;
  font-weight: 400;
  justify-content: center;
}

.height-data > label,
.weight-data > label,
.age-data > label {
  color: var(--color);
}

.weight-data > input,
.age-data > input,
.height-data > input {
  width: 100%;
  height: 2rem;
  border-radius: 8px;
  background-color: var(--lightGray);
  margin-top: 0.5rem;
  border: 1px solid var(--color);
  outline: none;
  color: var(--color);
  padding: 5px 10px;
}

.height-data,
.age-data,
.weight-data {
  margin: 1rem 1rem;
}

.form-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  margin-top: 2rem;
}

.bmi-btn{ 
  border-radius: 8px;
}

.gender {
  width: 30%;
  border-radius: 12px;
  height: 3rem;
  padding: 5px 10px;
  outline: none;
  background-color: var(--lightGray);
  border: 1px solid var(--color);
  color: var(--color);
  cursor: pointer;
}

.gender > option {
  color: black;
  font-weight: 500;
  font-size: 1rem;
}

@media screen and (max-width: 950px) {
  .bmi-page {
    gap: 2rem;
  }
  .bmi-right {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .bmi-page {
    flex-direction: column;
    gap: 2rem;
  }
  .glass,
  .bmi-right {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .bmi-page {
    padding: 0 1rem;
  }
  .glass {
    height: 470px;
  }
  .form-btn {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  .bmi-h5 {
    font-size: 1.5rem;
  }
  .bmi-form {
    padding: 1rem 0;
  }
}
