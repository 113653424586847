.about {
    width: 100%;
}

.aboutUs{
    display: flex;
    gap: 1.5rem;
    padding: 0 2rem;
    margin-top: 2rem;
}

.aboutUs-left {
    width: 55%;
    height: 24rem;
}

.aboutUs-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
}

.aboutUs-right {
    width: 40%;
    text-align: justify;
    line-height: 1.5rem;
}

/* MAP SECTION */

.map{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--black);
    padding: 1rem 2rem;
    margin-top: 2rem;
}

.map-details{
    width: 40%;
    border-radius: 20px;
    padding: 0 2rem;
    margin-top: 1rem;
}

.map-link{
    width: 50%;
    height: 400px;
    padding: 1rem 0;
}

.map-details-span{
    font-size: 1rem;
    color: var(--secondary);
    font-weight: 600;
}

.map-details-h3{
    font-size: 1.2rem;
    line-height: 2rem;
    color: var(--light);
    font-weight: 600;
}

.map-address{
    margin-top: 2rem;
}

.map-icons{
    list-style: none;
    display: flex;
    gap: 3rem;
    padding: 10px 0;
}

.map-icons > svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--secondary);

}

.map-span{
    font-size: 1rem;
    margin-top: .1rem;
}

.map-link iframe {
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
}


@media screen and (max-width: 800px) {
    .aboutUs {
        flex-direction: column;
    }
    .aboutUs-left, .aboutUs-right {
        width: 100%;
    }
    .map{
        flex-direction: column;
        align-items: flex-start;
    }
    .map-details, .map-link{
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .aboutUs {
        padding: 0 1rem;
    }
    .aboutUs-left, .aboutUs-right {
        height: 100%;
    }
    .map{
        padding: 0 1rem;
    }
    .map-details{
        padding: 0 1rem;
    }
    .map-span{
        font-size: 0.9rem;
    }
}

@media screen and (max-width:400px) {
    .map-details {
        padding: 0;
    }    
    .map-details-span {
        font-size: 1.2rem;
    }
    .map-details-h3 {
        font-size: 1rem;
    }
    .map-icons {
        align-items: center;
        gap: 1rem;
        padding: 10px 0;
    }
    .map-icons svg {
        margin-top: 0rem;
    }
}
