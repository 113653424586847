.task-title {
  padding: 1rem 1.2rem;
}

/* INPUT TASK FORM  */
.addTask-container{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}
.addTask-container form .input-task {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
}

/* Main Task Page */
.main-task{
  display: flex;
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 1rem 0;
  margin-top: 1rem;
}
.maintask-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 0  1rem;
}
.main-title{
  color: var(--dash-dark);
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 600;
}
.assigned-container, .new-assigned-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.ass-username{
  color: var(--dash-dark);
}
.ass-task{
  color: var(--dash-primary);
  margin-top: 5px;
  font-weight: 500;
}
.ass-right > input{
  cursor: pointer;
}
.ass-right > .ass-due-date{
  color: var(--dash-primary);
  font-size: .8rem;
  font-weight: 600;
  margin-top: 5px;
}
.unique-id {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 40%;
  gap: 1rem;
  margin: 5px;
  color: var(--dash-dark);
}
.unique-id input {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .main-task{
    flex-direction: column;
  }
  .main-task > hr {
    margin: 1rem 0;
  }
  .new-assigned-container{
    flex-direction: column;
  }
  .new-assigned-container > .ass-right, .new-assigned-container .ass-left{
    width: 100%;
  }
  .plans-form form div .unique-id {
    width: 100%;
  } 
  .tabs {
    font-size: 0.9rem;
    overflow: hidden;
  }

}

@media screen and (max-width: 400px) {
  .tabs {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 1500px) {
  .main-title {
    font-size: 1.7rem;
  }
  .ass-username {
    font-size: 1.3rem;
  }
  .ass-task {
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }
  .ass-right > .ass-due-date {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  .product-input-div {
    font-size: 1.5rem;
  }
}
