.cartpage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cart-container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
}

.main-cart {
  padding: 1rem 1.2rem;
  border-radius: 10px;
  width: 60%;
}

.cart-forward {
  width: 40%;
}

.no-carts-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.no-carts-icon{
  transform: scale(1.4);
}

.product-cart {
  background-color: black;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
}

.product-cart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
}

.cart-card {
  display: flex;
  gap: 3rem;
  background-color: var(--primary);
  border-radius: 10px;
  padding: 0rem 1.2rem;
}

.cart-left {
  width: 25%;
}

.cart-total {
  color: var(--color);
}

.cart-left > img {
  width: 100%;
  height: 12rem;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

.cart-right {
  width: 75%;
}

.cart-up {
  display: flex;
  justify-content: space-between;
  color: var(--secondary);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
}

.cart-down {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-start;
  color: var(--color);
  margin: 1rem 0;
}

.cart-size {
  border: 1px solid #999393;
  padding: 10px 16px;
  border-radius: 10px;
}

.cart-number {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cart-number > span {
  color: var(--secondary);
  font-size: 1.2rem;
}

.cart-btn,
.delete-icon {
  cursor: pointer;
}

.cart-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 1rem;
  gap: 1rem;
}

.cart-box {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  border: 1px solid #999;
  padding: 1rem;
  border-radius: 10px;
  flex-grow: 1;
  flex-shrink: 0;
}

.cart-key {
  color: var(--color);
  font-size: 1rem;
}

.cart-value {
  color: var(--light);
  font-size: 1rem;
}

.promo-code {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.promo-ad {
  color: var(--color);
  font-weight: 600;
}

.promo-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 1rem;
  width: 100%;
}

.promo-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
  flex-shrink: 0;
}

.promo-input {
  width: 50%;
  border: 1px solid #999;
  padding: 1rem 1.2rem;
  border-radius: 10px;
  outline-width: 0;
}
.promo-input:disabled {
  background-color: var(--white);
  cursor: not-allowed;
}

.promo-btn {
  background-color: var(--secondary);
  padding: 1rem 1.2rem;
  color: var(--color);
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .cart-card {
    gap: 2rem;
    padding: 0;
  }
  .cart-left {
    width: 33%;
  }
  .cart-right {
    width: 67%;
  }
}

@media screen and (max-width: 786px) {
  .cart-container {
    flex-direction: column;
  }
  .main-cart {
    padding: 1rem 0rem;
    width: 100%;
  }
  .cart-forward {
    width: 100%;
  }
  .cartpage .website-header {
    font-size: 1.5rem;
  }
  .product-cart-container {
    gap: 0.6rem;
  }
  .product-cart {
    padding: 0.6rem;
  }
  .cart-left > img {
    height: 7rem;
  }
  .cart-card {
    gap: 1rem;
    padding-right: 1rem;
  }
  .cart-up {
    font-size: 1rem;
  }
  .cart-down {
    gap: 1rem;
  }
  .cart-number > span {
    font-size: 1rem;
  }
  .delete-icon,
  .cart-btn {
    width: 1rem;
  }
  .cart-size {
    padding: 6px 8px;
  }
}

@media screen and (max-width: 400px) {
  .cart-up {
    flex-direction: column;
  }
  .cart-left > img {
    height: 7rem;
  }
  .cart-card {
    gap: 1rem;
    padding: 0;
  }
  .cart-up {
    font-size: 1rem;
  }
  .cart-down {
    gap: 1rem;
  }
  .cart-number > span {
    font-size: 1rem;
  }
  .delete-icon,
  .cart-btn {
    width: 1rem;
  }
}

@media screen and (max-width: 300px) {
  .cartpage .website-header {
    font-size: 1.2rem;
  }
  .cart-up {
    font-size: 0.8rem;
  }
  .cart-down {
    gap: 0rem;
  }
  .cart-size {
    font-size: 0.7rem;
  }
  .cart-number {
    gap: 0rem;
  }
}
