.google-form{
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  color: var(--black);
}
.google-form-header{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.google-form-header-left {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.google-form-main-icon {
  color: purple;
}
.google-form-name {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary);
  width: 7rem;
}
.google-form-name::placeholder{
  font-weight: normal;
}
.google-form-main{
  padding-top: 1rem;
  display: flex;
  align-items: center;
  background-color: var(--lightpink);
  justify-content: center;
}
.google-question-form {
  height: 100%;
  background: var(--white);
  border-radius: 10px;
  width: 66vw;
  margin-bottom: 1rem;
  box-shadow: var(--box-shadow);
  border-top: 8px solid rgba(102, 58, 131, 1);
}
.google-question-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.google-question-form-top{
  border-radius: 8px;
  padding: 1rem 1.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.question-for-top-name {
  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2rem;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
  height: 2rem;
  cursor: pointer;
}
.question-for-top-desc {
  margin-top: 0.5rem;
  font-size: 1rem;
}
.google-form-select{
  font-size: 0.8rem;
}
.google-question-form-top .product-input-div .product-label{
  font-size: 0.9rem;
  color: var(--black);
} 
.google-form-uniqueId{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.google-question-form-top .product-input-div .unique-id{
  flex: 1 0 340px;
}
.uniqueId-h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.google-btn{
  max-width: fit-content;
}
.ui{
  background-color: var(--lightpink);
}
.ui2{
  width: 100%;
}
.dragIndicatorIcon{
  transform: rotate(-90deg);
  color: var(--black);
  position: relative;
  left: 45%;
}
.saved-questions{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ui4{
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .1px;
  padding-bottom: 8px;
}
.ui5{
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
}
.ui13 {
  margin-right: 3px;
  margin-bottom: 3px;
}
.ui6{
  margin-left: 5px;
  margin-bottom: 5px;
}
.question-boxes {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.add-questions {
  border-radius: 8px;
  padding: 1.2rem 1rem;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  width: 100%;
}
.add-question-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ui7{
  color: #5f6368;
}
.question-input {
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  flex: 1;
  width: 50%;
  border: none;
  outline: none;
  height: 2rem;
}
.question-input:focus {
  border-bottom: 1px solid rgba(102, 58, 131, 1);
}
.google-select-questions {
  height: 2rem;
  width: 35%;
  margin: 0 10px;
  border: 1.5px solid #f4f4f9;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
}
.add-question-body {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.ui9{
  margin-right: 10px;
  color: var(--lightgray);
}
.ui8{
  display: flex;
  width: 100%;
}
.google-text-input, .text-input {
  outline: none;
  border: none;
  height: 40px;
  width: 60%;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #202124;
}
.google-text-input:focus, .text-input:focus {
  border-bottom: 1.4px solid rgba(102, 58, 131, 1);
}
.text-input{
  width: 6rem;
  border-bottom: 1px solid var(--color);
}
.ui10{
  color: var(--lightgray);
}
.ui11{
  margin: 0 10px;
  color: var(--lightgray);
}
.ui12{
  max-width: fit-content;
  font-size: 10px;
}
.add-footer {
  display: flex;
  width: 100%;
  margin-top: 13px;
}
.add-footer-bottom {
  border: 1px solid rgba(118, 118, 118, 0.4);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


/* user form  */

.submit {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
}
.submit_form{ 
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 1rem;
}
.submit_form_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.submit-title-1 { 
  font-size: 1.5rem;
  font-weight: 600;
}
.submit-title-2 { 
  font-size: 1rem;
  font-weight: 500;
}
.user_form_questions{
  display: flex;
  flex-direction: column;
  gap: .1rem;
  width: 100%;
}
.user_form_span {
  font-size: 1rem;
  font-weight: 500;
}
.form-userForm { 
  display: flex;
  align-items: center;
  margin-right: 8px;
  width: 100%;
}
.form-check-input{
  padding: 0.3rem 1rem;
  border-radius: 7px;
  border: 1px solid #999;
  outline: none;
}

.form-width {
  width: 80vw;
}

@media screen and (max-width: 768px) {
  .google-question-form {
    width: 95vw;
  }  
  .form-width {
    width: 100%;
  }
  .submit {
    padding: 0.5rem;
  }
  .submit_form{ 
    padding: 0.85rem;
  }
  .submit-title-1 { 
    font-size: 1.15rem;
  }
  .submit-title-2, 
  .user_form_span {
    font-size: 0.95rem;
  }  
  .form-check-input, 
  .form-userform-label {
    font-size: 0.8rem;
  }
  .google-question-form-top .product-input-div .unique-id{
    flex: 1 0 100%;
  }
  
}
