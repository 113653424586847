.analysis {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.2rem;
  margin-top: 10px;
}

.widgets {
  display: flex;
  margin-bottom: 1rem;
}

.widget-title {
  text-transform: uppercase;
}

.charts {
  display: flex;
  gap: 1.2rem;
  margin-top: 1rem;
}

.widget-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.widget {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  min-width: 250px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}

.left .dashboard-title {
  font-size: 0.8rem;
}

.left .counter {
  font-size: 1.4rem;
  font-weight: 400;
}

.left .link {
  font-size: 0.81rem;
  border-bottom: 1px solid var(--dash-primary);
  width: max-content;
}

.right .percentage {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.right .positive {
  color: green;
}

.right .negative {
  color: red;
}

.right .icon {
  font-size: 2rem;
  padding: 5px;
  border-radius: 7px;
  align-self: flex-end;
}

/* COLLECTION PAGE CSS */

.collection-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.collection {
  display: flex;
  flex-wrap: wrap;
  gap: 1.1rem;
}

.collection1 {
  flex: 1 0 200px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.collection2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

@media screen and (min-width: 1500px) {
  .widget {
    flex: 1 0 300px;
  }  
  .left .counter {
    font-size: 1.7rem;
    font-weight: 400;
  }
  .left .dashboard-title {
    font-size: 1.4rem;
  }
  .left .link {
    font-size: 1.1rem;
  }
  .collection-h3{
    font-size: 1.2rem;
  }
}