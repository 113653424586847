.prescription-div { 
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--dash-dark);
    margin-bottom: 1rem;
}
.prescription-span { 
    font-size: 0.95rem;
    font-weight: 500;
    color: var(--black1);
}
.prescription-weeks { 
    display: flex;
    flex-direction: column;
    gap: 1rem;   
}
.prescription-weeks-h3 { 
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--black);
}
.prescription-weeks1 {
    padding-left: 1rem;
    padding-bottom: 0.5rem;
}
.prescription-weeks1 .Link{ 
    text-decoration: none;
    cursor: pointer;
    color: var(--dash-dark);
    font-weight: 500;
    text-decoration: underline;
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
}
.prescription-btn-container { 
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.prescription-btn-1,
.prescription-btn-2,
.prescription-btn-3 { 
    border-radius: 5px;
    padding: 0.3rem 1rem;
    outline: none;
    font-weight: 500;
    border: 1px solid var(--black1);
    cursor: pointer;
    background-color: var(--black);
    color: var(--white);
}
.prescription-btn-1 { 
    color: var(--black1);
    background-color: var(--white);
}
.prescription-btn-3 { 
    display: flex;
    align-items: center;
    gap: 2px;
}
.prescription-btn { 
    width: 100%;
    margin-top: 1rem;
}

/* day prescription  */
/* ****************************** */

.prescription-day { 
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--lightgray);
}
.prescription-day-grid { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.prescription-day-1 { 
    display: flex;
    align-items: center;
}
.prescription-day-delete { 
    color: var(--red);
    transform: scale(0.75);
    margin-bottom: 0.5rem;
    cursor: pointer;
}


/* single exercise csss */
/* **************************** */
/* **************************** */
/* **************************** */

.single-exercise-iframe {
    width: 100%;
    margin-bottom: 1rem;
}
.iframe { 
    width: 70%;
    border-radius: 5px;
}
.single-exercise-container { 
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.single-exercise-div { 
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.single-exercise-div > label { 
    font-size: 1rem;
    font-weight: 600;
    color: var(--dash-dark);
}
.single-exercise-div > h4 { 
    font-size: 0.95rem;
    font-weight: 500;
    color: var(--dash-primary);
}

/* exercise list css */
/* ******************* */
.single-exercise-title{ 
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--dash-dark);
    margin-bottom: 1rem;
}
.single-exercise-lists{ 
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.single-exercise-list { 
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-decoration: none;
}
.single-exercise-main-div { 
    display: flex;
    gap: 1rem;
}
.single-exercise-list1 { 
    width: 50%;
    height: 100%;
}
.single-exercise-list1-img { 
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}
.single-exercise-list2 { 
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.single-exercise-list2-span { 
    font-size: 1rem;
    color: var(--dash-primary);
    font-weight: 600;
}
.single-exercise-list-h3{ 
    font-size: 1rem;
    color: var(--dash-dark);
    font-weight: 600;
}

/* exercise-weeks css */
/* ********************************* */

.single-exercise-week {
    background-color: var(--lightpink);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden; */
    color: var(--dash-dark);
    font-size: 1rem;
}

.single-exercise-week-lists { 
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.single-exercise-week-list{ 
    flex: 1 0 250px;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 5px;
    font-weight: 600;
    text-decoration: none;
    color: var(--dash-dark);
    background-color: var(--lightpink);
}

/*  */

@media screen and (max-width: 900px) {
    .iframe { 
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .single-exercise-div { 
        flex-direction: column;
        gap: 0.2rem;
        align-items: flex-start;
    }
    .prescription-day-grid { 
        grid-template-columns: 1fr;
        gap: 0;
    }    
    .single-exercise-week-lists { 
        gap: 0.4rem;
    }
    .single-exercise-week-list{ 
        flex: 1 0 220px;
        padding: 0.5rem 0.75rem;
        font-size: 0.95rem;
    }   
    .single-exercise-title{ 
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    .single-exercise-main-div { 
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .single-exercise-list1 { 
        width: 100%;
    }
    .single-exercise-list2-span { 
        font-size: 0.95rem;
    }   
    .single-exercise-week {
        padding: 0.3rem 0.5rem;
        font-size: 0.85rem;
    }
    
}

@media screen and (max-width: 600px) {
    .single-exercise-div > label { 
        font-size: 0.85rem;
    }
    .single-exercise-div > h4 { 
        font-size: 0.85rem;
    }
}
