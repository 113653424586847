.notifications {
    position: relative;
    display: flex;
}

.notifyIcon {
    color: var(--dash-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.notificationBox, .notificationBox1{
    top: 2.7rem;
    right: 0.5rem;
    position: absolute;
    width: 23rem;
    background-color: var(--white);
    height: 23rem;
    overflow: auto; /* Enable scrolling */    
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 10px 0px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 13px;
    z-index: 999;
}
.notificationBox::-webkit-scrollbar, .notificationBox1::-webkit-scrollbar {
    display: flex;
    width: 8px; /* Set a width for the scrollbar */
  }
.notificationBox::-webkit-scrollbar-thumb, .notificationBox1::-webkit-scrollbar-thumb {
    background-color: var(--dash-primary);
    border-radius: 4px;
}
.notificationBox::-webkit-scrollbar-thumb:hover, .notificationBox1::-webkit-scrollbar-thumb:hover {
    background-color: var(--dash-tertiary);
}

.notification-h4 {
    color: var(--dash-dark);
    position: sticky;
    font-weight: 600;
    top: 0%;
    margin-bottom: 1rem;
}

.notification-user {
    display: flex;
    grid-gap: 2rem;
    border-top: 1px solid var(--dash-gray);
    padding: 1.2rem 0;
}

.notification-photoUrl>img{
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 50%;
}

.buttons{
    margin-top: 1rem;
    display: flex;
    grid-gap: 10px;
}

.cta-btn {
    font: inherit;
    background-color: transparent;
    border: 1px solid var(--green);
    background: rgba(32, 201, 10, 0.208);
    color: var(--green);
    max-width: 4rem;
    height: 2rem;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
}

.notifyTime{
    font-size: 0.9rem;
    margin-top: 10px;
    color: var(--light);
}
.notifyMessage{
    color: #161545;
    font-size: 0.8rem;
    font-weight: 500;
}
.textBold{
    font-weight: 600;
}

/* advertisement js */

.ad-container{
    margin-top: 1rem;
}

.ads{
    width: 100%;
    margin-top: 1rem;
}

.ads-container {
    width: 15rem;
    height: 10rem;
    position: relative;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
}

.adsImg{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.ad-delete-icon{
    color: var(--red);
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    cursor: pointer;
}
.ads-time{
    margin-top: 0.6rem;
    font-size: 0.9rem;
}
.icons {
    cursor: pointer;
    transform: scale(0.75);
    color: var(--black1);
}

@media screen and (max-width: 600px) {
    .notificationBox{
        right: -3.5rem;
        width: 70vw;
        height: 14rem;
    }
    .notificationBox1{
        right: -5.5rem;
        width: 70vw;
        height: 14rem;
    }
    .user {
        grid-gap: 1rem;
        padding: 1.2rem 0;
    }
    .notifyTime {
        margin-top: 0px;
    }
    .cta-btn {
        max-width: 6rem;
        height: 2rem;
        width: 35%;
    }
}