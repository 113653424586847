.footer{
    background-color: var(--black);
    padding: 0 1.3rem;
    margin-top: 4rem;
    position: relative;
}
.footer-content{
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}
.footer-left>div{
    font-size: 2rem;
    margin-top: 2rem;
    font-weight: 500;
}
.footer-left>span{
    font-size: 1rem;
    color: var(--light);
}
.newsletter>h5{
    color: var(--light);
    font-size: 1rem;
    font-weight: 400;
}
.email-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--lightgray);
    margin-top: 1rem;
    padding: 0.4rem 0;
    border-radius: 7px;
}
.email-container>input{
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    font-size: 0.85rem;
    font-weight: 400;
    outline: none;
    color: var(--color);
}
.email-container>input::placeholder { 
    color: var(--color);
    opacity: 0.7;
}
.email-container > .btn { 
    border-radius: 7px;
    padding: 0.4rem 0.8rem;
    margin-right: 0.5rem;
}
.footer-right{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    flex-wrap: wrap;
}
.about-us{
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    font-size: 1.2rem;
    flex-direction: column;
    font-weight: 400;
    gap: 1rem;
}
.footer-icon{
    color: var(--light);
}

.footer-icon:hover,
.footer-links-icon:hover,
.footer-links-icon:hover .footer-title{
    color: var(--secondary);
    transition: .4s ease;
}

.about-us>ul{
    text-decoration: none;
    list-style: none;
    color: var(--color);
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.about-us>ul>.footer-links{
    color:var(--light);
    text-decoration: none;
    margin: 6px 0;
    font-weight: 400;
}

.footer-links-icon{
    text-decoration: none;
    color: var(--light);
    font-weight: 400;
}
 
.last-line{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.about-us>ul>.footer-links:hover{
    cursor: pointer;
    color: var(--secondary);
    transition: .4s ease;
}


@media screen and (max-width: 768px) {
    .footer-content{
        flex-direction: column;
    }   
    .last-line{
        font-size: small;
        margin: 1rem 0;
    }
    .newsletter>h5{
        margin-right:8rem;
    }
    .footer-right {
        justify-content: space-around;
    }
}

@media screen and (max-width:400px) {
    .footer{
        padding: 0 1rem;
        margin-top: 3rem;
    }
    .footer-left>div {
        font-size: 1.2rem;
    }
    .footer-left > span {
        font-size: 0.8rem;
    }
    .newsletter > h5 {
        font-size: 0.8rem;
        margin-right: 1rem;
    }
    .about-us>ul>.footer-links {
        font-size: 0.8rem;
    }
    .last-line{
        padding: 0;
        margin: 1rem 0;
        font-size: 0.8rem;
        text-align: center;
    }
    .email-container>input{
        padding: 0;
        margin-left: 5px;
        font-size: 0.75rem;
    }
    .email-container > .btn { 
        margin-right: 5px;
    }
    
}
