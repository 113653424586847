.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 111;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: white;
  padding: 3rem;
  height: 450px;
  width: 50%;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
}

.modal-content::-webkit-scrollbar {
  display: flex;
  width: 8px; /* Width of the scrollbar */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #120897; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the thumb */
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

.form-group > div {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 0.2rem;
}

.form-group > div > label {
  font-weight: 600;
  font-size: 0.8rem;
  color: var(--dash-primary);
}

.form-group > div > input {
  cursor: pointer;
  color: var(--dash-dark);
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group > label {
  color: var(--dash-dark);
  font-weight: 600;
  margin-top: 1rem;
}

.form-group > input,
.form-group > select {
  padding: 0.3rem 2rem;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: 1px solid var(--dash-dark);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content {
    background-color: white;
    padding: 1rem 3rem;
    border-radius: 10px;
    position: relative;
    height: 400px;
    width: 400px;
    overflow: auto;
  }
  .fc-direction-ltr {
    direction: ltr;
    text-align: left;
    height: 27rem;
  }
}
