.dropdown {
  position: relative;
  perspective: 200px;
  z-index: 15;
}
.dropdown .d-btn {
  display: flex;
  align-items: center;
  gap: 3rem;
  width: 100%;
  height: 72px;
  color: var(--color);
  background: transparent;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
}

.dropdown > .d-btn {
  position: relative;
  z-index: 12;
  transition: 0.3s;
}

.d-btn > span {
  color: var(--secondary);
  font-weight: 600;
}

.menu1 {
  position: absolute;
  overflow: hidden;
  z-index: 997;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 180px;
  opacity: 0;
  font-weight: 500;
  transform-origin: 100% 0;
  scale: 0;
  visibility: hidden;
  border-radius: 8px;
  background: rgba(241, 232, 232, 0.658);
  transition: 0.5s;
}

.dropdown.open .menu1 {
  display: flex;
  scale: 1;
  right: 0;
  top: 72px;
  opacity: 1;
  visibility: visible;
}

.menu1 .b-btn {
  border: 0;
  width: 100%;
  height: 56px;
  border-radius: 0;
  display: flex;
  background-color: var(--color);
  align-items: center;
  padding: 0.3rem 1rem;
  gap: 1rem;
  font-weight: 600;
}

.menu1 .Link {
  text-decoration: none;
  color: var(--primary);
}

.menu1 .b-btn:hover {
  background: rgba(255, 255, 255, 0.16);
}

.chevron {
  margin-left: auto;
  transition: 0.4s;
}

.dropdown.open .chevron {
  rotate: -180deg;
}

@media screen and (max-width: 800px) {
  .dropdown .d-btn {
    font-size: 16px;
    margin-right: 2rem;
  }

  .dropdown > .d-btn {
    position: relative;
    z-index: 12;
    transition: 0.3s;
  }
}

@media screen and (max-width: 500px) {
  .dropdown .d-btn {
    font-size: 13px;
    margin-right: 1.5rem;
  }

  .dropdown > .d-btn {
    position: relative;
    z-index: 12;
    transition: 0.3s;
  }
}

@media screen and (max-width: 320px) {
  .dropdown .d-btn {
    font-size: 12px;
    margin-left: -32px;
  }

  .dropdown > .d-btn {
    position: relative;
    z-index: 12;
    transition: 0.3s;
  }
}
