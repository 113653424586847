.productForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1.2rem;
}

.product-form-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  padding: 1rem 1.2rem;
}

.product-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.product-input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-label {
  font-size: 1.1rem;
  color: var(--dash-primary);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.product-input-div input,
.product-input-div textarea,
.product-input-div select {
  width: 100%;
  padding: 0.6rem 1rem;
  border: 1px solid #999;
  outline: none;
  border-radius: 10px;
}

.product-input-div select {
  cursor: pointer;
}

.product-sizes {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.product-size-map {
  flex-basis: 33%;
  padding: 0.6rem 1rem;
  border: 1px solid #999;
  outline: none;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.cancel-icon {
  cursor: pointer;
  color: var(--red);
}

.add-btn {
  width: 8rem;
  background-color: var(--dash-primary);
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.productImgContainer {
  position: relative;
  margin-right: 10px;
}

.product-img {
  width: 12rem;
  height: 8rem;
  border-radius: 5px;
  object-fit: cover;
}

.delete-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: var(--color);
  cursor: pointer;
}

.no-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.no-product-h1 {
  color: var(--black);
  font-size: 1.5rem;
}

.no-product-span {
  color: var(--dash-primary);
  font-weight: 500;
}

.showProduct {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  width: 100%;
}

.show-product {
  display: flex;
  flex-direction: column;
  background-color: var(--lightpink);
  border-radius: 10px;
  gap: 1rem;
  padding: 1rem;
}

.show-left {
  width: 100%;
  height: 15rem;
  overflow: hidden;
  border-radius: 10px;
}

.show-left > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: grab;
}

.show-left > img:hover {
  transform: scale(1.01);
  transition: 0.2s ease-in-out;
}

.show-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.show-right-name {
  display: flex;
  justify-content: space-between;
  margin-bottom: -0.8rem;
}
.show-right-name1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.show-name {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  color: var(--black);
}
.show-price {
  color: var(--dash-dark);
  font-weight: 600;
  font-size: 14px;
}
.show-button {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.show-button > .delete-product-btn,
.show-button > .update-btn {
  padding: 0.6rem 1.2rem;
  outline: none;
  border: none;
  border-radius: 10px;
  color: var(--color);
  cursor: pointer;
}

.show-button > .delete-product-btn {
  background-color: var(--red);
}

.show-button > .update-btn {
  background-color: var(--green);
}

.AddTagContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.addTagBox {
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #999;
  padding: 1rem;
  font-weight: 500;
}

.addTagBox .tag-h2 {
  font-size: 1rem;
  color: var(--dash-primary);
}

.addTagInput {
  margin-top: 1rem;
  display: flex;
  gap: 10px;
}

.inputTag {
  border: none;
  font: inherit;
}

.inputTag:focus {
  outline: none;
}

.tags {
  background-color: #ececf8;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  position: relative;
  flex-direction: column;
  font-weight: 600;
  grid-gap: 5px;
  align-items: center;
}

.crossIcon {
  cursor: pointer;
}

.editorForm {
  position: relative;
}

.editor-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  background-color: rgba(0, 0, 0, 0.71);
  display: flex;
  height: 85vh;
  width: 75vw;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 100;
}

.editor-modal-content {
  background-color: #fff;
  position: relative;
  width: 60vw;
  height: 400px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.editor-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  cursor: pointer;
}

.editor-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.editor-modal-content form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editor-article {
  text-align: justify;
  overflow: auto;
  margin-top: 20px;
}

.editor-left {
  width: 20rem;
  height: 12rem;
}

.editor-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .editor-modal {
    height: 100vh;
    width: 100vw;
    transform: translate(-50%, -50%);
  }
  .editor-modal-content {
    padding: 1rem;
    width: 90vw;
    height: 80vh;
  }
}

@media screen and (max-width: 400px) {
  .showProduct {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
  .show-left {
    height: 10rem;
  }
  .show-price {
    font-size: 0.8rem;
  }
  .show-title {
    font-size: 0.95rem;
  }
}


@media  screen and (min-width: 1500px) {
  .showProduct {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 1rem;
  }
  .product-input-div input, .product-input-div textarea, .product-input-div select {
    font-size: 1.2rem;
  }
  .product-label {
    font-size: 1.4rem;
  }
  .show-title{
    font-size: 1.5rem;
  }
  .show-price {
    font-size: 1.4rem;
  }
  .dashMembers-dropdown {
    transform: scale(1.5);
  }
}