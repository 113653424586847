.side-icon {
  position: absolute;
  top: 4px;
  left: 1rem;
  background-color: #f0f3fa;
  color: var(--dash-dark);
  padding: 1px;
  border-radius: 10px;
  z-index: 19;
  width: 1.6rem;
  height: 1.6rem;
  transition: left 300ms ease;
  display: none;
  cursor: pointer;
}
.segment-icon {
  color: var(--littleDark);
  transform: scale(0.7) rotateY(180deg);
}
.side-icon.expanded {
  left: 12.2rem;
  top: 0.51rem;
}
.sidebar {
  border-right: 0.5px solid var(--light);
  display: flex;
  flex-direction: column;
  width: 12rem;
  position: sticky;
  top: 0%;
  bottom: 0%;
  transition: all 300ms ease;
  height: 100vh;
  overflow: auto;
}
.sidebar::-webkit-scrollbar {
  display: flex;
  width: 5px;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--dash-secondary);
  border-radius: 4px;
}
.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: var(--dash-tertiary);
}
.sidebar .top {
  height: 2.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.sidebar .top .img {
  width: 1.3rem;
  border-radius: 10px;
}
.sidebar .top .logo {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--dash-dark);
}
.sidebar hr {
  height: 0;
  border: 0.5px solid var(--light);
}
.center {
  padding-left: 1rem;
}
.center > ul {
  list-style: none;
}
.center > ul > .dashnav-links {
  display: flex;
  align-items: center;
  padding: 0.15rem;
  gap: 0.5rem;
  font-weight: 500;
  transform: scale(0.95);
  color: var(--dash-dark);
  text-decoration: none;
  font-size: 0.92rem;
  cursor: pointer;
}
.center > ul > .dashnav-links > li {
  color: var(--dash-primary);
  font-weight: 600;
}
.center > ul > .dashnav-links:hover {
  background-color: var(--dash-fourth);
  transition: 100ms ease-in-out;
  border-radius: 2px;
}

@media screen and (max-width: 1200px) {
  .sidebar {
    width: fit-content;
  }
  .sidebar .top .logo {
    display: none;
  }
  .center {
    padding: 0 3px;
  }
  .center > ul > .dashnav-links > li {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    z-index: 19;
    background: var(--lightpink);
    width: 16rem;
    padding-right: 1rem;
    height: 100%;
  }
  .center > ul > .dashnav-links:hover {
    background-color: var(--dash-background);
  }
  .center > ul > .dashnav-links {
    padding: 0.1rem;
    gap: 0.5rem;
    transform: scale(0.85);
  }
  .sidebar .top .logo {
    display: flex;
    font-size: 1rem;
  }
  .center > ul > .dashnav-links > li {
    display: block;
    font-size: 0.9rem;
  }
  .side-icon {
    display: block;
  }
  .side-icon.expanded {
    left: 16rem;
  }
  .sidebar.expanded .side-icon {
    right: -2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar:not(.expanded) .side-icon {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    transform: none;
  }
}

@media screen and (min-width: 1500px) {
  .sidebar {
    width: 20rem;
  }
  .center > ul > .dashnav-links {
    font-size: 1.15rem;
    padding: 0.3rem;
    gap: 0.4rem;
    transform: scale(0.94);
  }
  .sidebar .top .logo {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .sidebar {
    width: 62%;
  }
  .side-icon.expanded {
    left: 62%;
  }
}
